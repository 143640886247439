import React from "react";

import { Switch, Tooltip } from "@mui/material";

const StatusToggleSwitch = ({ row, companyMapping, setCompanyMapping }) => {
  const handleStatusToggleChange = (e, rowData) => {
    setCompanyMapping(
      companyMapping.map((row) =>
        row.orgId === rowData.id
          ? {
              ...row,
              enabled: e.target.checked,
            }
          : row
      )
    );
  };

  return (
    <Tooltip
      placement="right"
      title={`${
        row?.mappingEnabled ? "Disable" : "Enable"
      } mapping on account for ${row?.blumiraAccount}`}
    >
      <Switch
        checked={row?.mappingEnabled}
        onChange={(e) => handleStatusToggleChange(e, row)}
        inputProps={{
          "aria-label": `${
            row?.mappingEnabled ? "Disable" : "Enable"
          } mapping on account for ${row?.blumiraAccount}`,
        }}
      />
    </Tooltip>
  );
};

export default StatusToggleSwitch;
