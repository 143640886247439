const orgSchema = {
  $id: "/org",
  title: "Organization",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    config: {
      type: "object",
      properties: {
        userCount: {
          type: "number",
          minimum: 1,
        },
        additionalProperties: true,
      },
    },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    locations: { type: "array", items: { $ref: "/location" }, readOnly: true },
    market: { type: "number" },
    modified: { type: "string", format: "date-time", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    name: {
      type: "string",
      minLength: 1,
      maxLength: 255,
      pattern: "^(?! )[\\S\\s]*(?<! )$",
    },
    notes: { type: "string", maxLength: 255 },
    orgType: { type: "string" },
    persons: {
      type: "array",
      readOnly: true,
      items: {
        type: "object",
        properties: {
          id: { type: "string", format: "uuid" },
          created: { type: "string", readOnly: true },
          email: { type: "string", format: "email" },
          firstName: { type: "string" },
          lastName: { type: "string" },
          lastLoginAt: { type: "string", readOnly: true, nullable: true },
          rolesInOrg: {
            type: "array",
            items: { type: "number" },
          },
        },
      },
    },
    shortName: { type: "string", maxLength: 255 },
    status: { type: "number", readOnly: true },
    webUrl: { type: "string", maxLength: 255 },
    stripeCustomerId: { type: "string" },
    maxioCustomerId: { type: "string" },
    mspSubaccountBillingDiscountType: { type: "string" },
    mspSubaccountBillingDiscountAmount: { type: "number" },
    mspSubaccountBillingDiscountStartDate: {
      type: "string",
      formt: "date-time",
    },
    mspSubaccountBillingDiscountEndDate: { type: "string", formt: "date-time" },
    mspSubaccountBillingNfrAccount: { type: "boolean" },
    autoIsolationEnabled: { type: "boolean" },
    changeManagementPolicy: { type: "boolean" },
    autoIsolationTypes: { type: "array", items: { type: "string" } },
    agentSeatCount: { type: "number" },
    userCount: { type: "number" },
    license: { type: "string" },
    licenseFeatures: {
      type: "array",
      items: { type: "string" },
    },
    parentId: { type: "string", format: "uuid", nullable: true },
    dataTableExpireDays: { type: "number", nullable: true },
    executiveSummaries: {
      // executiveSummaries translates to executive_summaries on the org's config in FEA
      type: "object",
      properties: {
        role_recipients: { type: "array", items: { type: "number" } }, // i.e. [10, 20, 40], Admins, Managers, Responders
        email_recipients: { type: "array", items: { type: "string" } }, // i.e. ["zeus@olympus.net", "hades@underworld.com"], If specified, email recipients
        content_sections: { type: "array", items: { type: "number" } }, // 1 is required, 2,3,4 are optional and included by default
        custom_text: { type: "string" },
        logo_url: { type: "string" },
      },
    },
    licenseScheduled: {
      type: "object",
      properties: {
        license_name: { type: "string" },
        start: { type: "string", format: "date-time" },
        end: { type: "string", format: "date-time" },
        created: { type: "string", format: "date-time" },
      },
    },
    psaOrgId: { type: "string", nullable: true },
    psaOrgEnabled: { type: "boolean", nullable: true },
  },
  required: ["name"],
  additionalProperties: false,
};

export default orgSchema;
