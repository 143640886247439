import {
  Alert,
  FormControl,
  List,
  ListItem,
  Stack,
  TextField,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import SecretField from "./SecretField";
import { classes } from "./styles";
import { areValuesEmpty } from "./ConfigurationForm";

export const renderResponseNotAllowed = () => {
  return (
    <Alert icon={false} severity={"info"}>
      Organization license does not currently support response.
    </Alert>
  );
};

const FieldsetResponse = (props) => {
  const ConfigSection = "configuration";
  const SecretSection = "secret";

  const { model, typeModel, disabled, onChange } = props;

  const allRequired = (configValues, secretValues) => {
    if (model.id) {
      return !areValuesEmpty(secretValues);
    }
    return !areValuesEmpty(secretValues) || !areValuesEmpty(configValues);
  };

  if (!model.configFields) {
    model.set({ configFields: {} }, false);
  }
  if (!model.secretFields) {
    model.set({ secretFields: {} }, false);
  }

  // If all values are empty then they are not required.
  // A user has not filled them out yet and we need to
  // allow response configuration to be optional.
  const [configFields, setConfigFields] = useState([]);
  const [secretFields, setSecretFields] = useState([]);
  const [configValues, setConfigValues] = useState(model.configFields);
  const [secretValues, setSecretValues] = useState(model.secretFields);

  useEffect(() => {
    let configFields = [];
    let secretFields = [];

    const initRequired = allRequired(configValues, secretValues);

    for (const fieldType of typeModel.configFieldTypes) {
      configFields.push({
        section: ConfigSection,
        label: fieldType.displayName,
        name: fieldType.fieldName,
        editable: true,
        required: initRequired,
        placeholder: initRequired ? "(required)" : "(optional)",
        validate: (value, newConfigValues, newSecretValues) => {
          const required = allRequired(newConfigValues, newSecretValues);
          if (required) {
            // TODO better validation based on `FieldType`
            return !!value && value !== "";
          }
          return true;
        },
      });
    }
    for (const fieldType of typeModel.secretFieldTypes) {
      secretFields.push({
        section: SecretSection,
        label: fieldType.displayName,
        name: fieldType.fieldName,
        editable: true,
        required: initRequired,
        placeholder: initRequired ? "(required)" : "(optional)",
        validate: (value, newConfigValues, newSecretValues) => {
          const required = allRequired(newConfigValues, newSecretValues);
          if (required) {
            // TODO better validation based on `FieldType`
            return !!value && value !== "";
          }
          return true;
        },
      });
    }

    setConfigFields(configFields);
    setSecretFields(secretFields);
  }, [configValues, secretValues]);

  const updateValidation = (newConfigValues, newSecretValues) => {
    let isOk = true;
    for (const info of configFields) {
      isOk =
        isOk &&
        info.validate(
          newConfigValues[info.name],
          newConfigValues,
          newSecretValues
        );
    }
    for (const info of secretFields) {
      isOk =
        isOk &&
        info.validate(
          newSecretValues[info.name],
          newConfigValues,
          newSecretValues
        );
    }
    onChange({ tabName: "Response", hasError: !isOk });
  };

  const handleChange = (fieldInfo, value) => {
    // State updates occur asynchronously so we need to save our
    // updates so that we can update the model and do validation
    // afterward.
    let newConfigValues = configValues;
    let newSecretValues = secretValues;

    if (fieldInfo.section === ConfigSection) {
      newConfigValues = {
        ...configValues,
        [fieldInfo.name]: value,
      };
      setConfigValues(newConfigValues);
    } else if (fieldInfo.section === SecretSection) {
      newSecretValues = {
        ...secretValues,
        [fieldInfo.name]: value,
      };
      setSecretValues(newSecretValues);
    } else {
      console.error("unexpected section", fieldInfo);
    }

    model.set({ configFields: newConfigValues }, false);
    model.set({ secretFields: newSecretValues }, false);

    updateValidation(newConfigValues, newSecretValues);
  };

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <Alert
        className={classes.threatResponseBox}
        icon={false}
        severity={"info"}
      >
        <p>
          <strong>Blumira Microsoft 365 Threat Response</strong> lets you
          respond to activity in your Microsoft 365 environment directly from
          Blumira. For example, you can lock out users or revoke their session
          tokens from supported findings in the app without signing into
          Microsoft 365.
        </p>
        <List>
          <ListItem>
            <strong>
              Ensure the tenant ID matches the tenant ID used in the Logging tab
            </strong>{" "}
            so that threat response can function correctly. You can verify
            tenant IDs by running the global report “Microsoft 365 Cloud
            Connectors and Tenant IDs.”
          </ListItem>
          <ListItem>
            <strong>Higher permissions are required</strong> in your Microsoft
            application than those needed for logging. Use the instructions
            below to perform the necessary configurations.
          </ListItem>
        </List>
        <Stack direction="row" spacing={3} sx={{ mt: 1 }}>
          <a
            href="https://blumira.help/response-connector"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Configuring a response connector
            <OpenInNewIcon />
          </a>
          <a
            href="https://blumira.help/response-actions"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            What responses are available?
            <OpenInNewIcon />
          </a>
        </Stack>
      </Alert>

      {configFields.map((fieldInfo, index) => (
        <TextField
          datacy={fieldInfo.name}
          value={configValues[fieldInfo.name] || ""}
          placeholder={fieldInfo.placeholder}
          label={fieldInfo.label}
          onChange={(e) => handleChange(fieldInfo, e.target.value)}
          className={classes.field}
          disabled={disabled || !fieldInfo.editable}
          key={ConfigSection + index}
          error={
            !fieldInfo.validate(
              configValues[fieldInfo.name],
              configValues,
              secretValues
            )
          }
          helperText={fieldInfo.helperText}
          InputLabelProps={{
            shrink: true,
          }}
        />
      ))}

      <p className={classes.secretsText}>
        <span className={classes.secretsTitle}>Secrets: </span>
        {model.id
          ? "Leave the following fields blank to leave this connector’s secrets unchanged."
          : "The following entries will be hidden when editing this cloud connector in the future."}
      </p>

      {secretFields.map((fieldInfo, index) => (
        <SecretField
          disabled={disabled}
          fieldInfo={fieldInfo}
          handleChange={handleChange}
          key={SecretSection + index}
          secretValue={secretValues[fieldInfo.name]}
          configValues={configValues}
          secretValues={secretValues}
        />
      ))}
    </FormControl>
  );
};

FieldsetResponse.propTypes = {
  model: PropTypes.object.isRequired,
  typeModel: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FieldsetResponse;
