import { Box, FormControl, Tab, Tabs, TextField } from "@mui/material";

import { TabContext, TabPanel } from "@mui/lab";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Root, classes } from "./styles";
import FieldsetLogging from "./FieldsetLogging";
import FieldsetResponse from "./FieldsetResponse";
import LicenseRestriction from "views/Components/License";
import { renderResponseNotAllowed } from "./FieldsetResponse";

export const areValuesEmpty = (values) => {
  let areEmpty = true;
  for (const value of Object.values(values)) {
    if (value && value !== "") {
      areEmpty = false;
      break;
    }
  }
  return areEmpty;
};

export const ConfigurationForm = (props) => {
  const RootSection = "";

  const isStringLengthValid = (string, maxLength) => string.length <= maxLength;
  const isStringCharactersValid = (string, regex) => regex.test(string);

  const nameFieldInfo = {
    section: RootSection,
    label: "Cloud Connector Name",
    name: "integrationName",
    editable: true,
    required: true,
    placeholder: "",
    validate: (value) => {
      return (
        isStringLengthValid(value, 255) &&
        isStringCharactersValid(value, /^[A-Za-z0-9 -]+$/)
      );
    },
  };

  const {
    disabled,
    handleTabChange,
    integration,
    integrationSchemas,
    license,
    onValidation,
    responseConnector,
    responseConnectorType,
    selectedCloudModule,
    superadmin,
    tabValue,
  } = props;

  const [nameValue, setNameValue] = useState(integration.integrationName || "");
  const [tabErrors, setTabErrors] = useState({
    Name: !nameFieldInfo.validate(integration.integrationName || ""),
    Logging: !integration.id,
    Response: false,
  });

  useEffect(() => {
    onValidation({ hasError: Object.values(tabErrors).some((e) => e) });
  }, [onValidation]);

  const integrationType = integration.integrationType;
  const integrationSchema = integrationSchemas.find(
    (schema) => schema.integrationType === integrationType
  );

  if (!integrationType || integrationType === "") {
    console.error("integration has no type");
    return <div />;
  }

  if (!integrationSchema) {
    console.error(
      "no schema for this integration type was returned from the server",
      integrationType
    );
    return <div />;
  }

  const handleChange = (changes) => {
    let errors = tabErrors;
    errors[changes.tabName] = changes.hasError;
    setTabErrors(errors);
    onValidation({ hasError: Object.values(errors).some((e) => e) });
  };

  const handleNameChange = (value) => {
    setNameValue(value);
    integration.set({ [nameFieldInfo.name]: value }, false);
    let errors = tabErrors;
    errors["Name"] = !nameFieldInfo.validate(value);
    setTabErrors(errors);
    onValidation({ hasError: Object.values(errors).some((e) => e) });
  };

  return (
    <Root>
      <FormControl component="fieldset" fullWidth={true}>
        <TextField
          autoFocus={true}
          value={nameValue}
          label={nameFieldInfo.label}
          datacy={nameFieldInfo.name}
          onChange={(e) => handleNameChange(e.target.value)}
          className={classes.field}
          disabled={disabled || !nameFieldInfo.editable}
          key={RootSection + nameFieldInfo.name}
          error={!nameFieldInfo.validate(nameValue)}
          helperText={
            !nameFieldInfo.validate(nameValue)
              ? "must be less than 256 characters and only contain letters, numbers, or dashes"
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>

      <TabContext value={tabValue}>
        {
          // TODO REMOVE BELOW LINE FOR GA
          license.isAllowedAPI("response.create") && (
            <Tabs
              value={tabValue}
              textColor={"primary"}
              indicatorColor={"primary"}
              onChange={handleTabChange}
            >
              <Tab label={"Logging"} value={"Logging"} />
              {responseConnectorType && (
                <Tab
                  datacy={"cloudResponseTab"}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      Threat Response
                    </Box>
                  }
                  value={"Response"}
                />
              )}
            </Tabs>
          )
        }
        <TabPanel value={"Logging"}>
          <FieldsetLogging
            model={integration}
            schema={integrationSchema}
            integrationType={integrationType}
            disabled={disabled}
            onChange={handleChange}
            selectedCloudModule={selectedCloudModule}
            superadmin={superadmin}
          />
        </TabPanel>
        {responseConnectorType &&
          // TODO REMOVE BELOW LINE FOR GA
          license.isAllowedAPI("response.create") && (
            <TabPanel value={"Response"}>
              <LicenseRestriction
                requires="response.create"
                renderDisallow={(children) => renderResponseNotAllowed()}
              >
                <FieldsetResponse
                  model={responseConnector}
                  typeModel={responseConnectorType}
                  disabled={disabled}
                  onChange={handleChange}
                />
              </LicenseRestriction>
            </TabPanel>
          )}
      </TabContext>
    </Root>
  );
};

ConfigurationForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired,
  integrationSchemas: PropTypes.array.isRequired,
  license: PropTypes.object.isRequired,
  onValidation: PropTypes.func.isRequired,
  responseConnector: PropTypes.object,
  responseConnectorType: PropTypes.object,
  selectedCloudModule: PropTypes.object.isRequired,
  superadmin: PropTypes.bool.isRequired,
  tabValue: PropTypes.string.isRequired,
};
