import MyAccountView from "./Pages/MyAccountView";
import MyAlertsView from "./Pages/MyAlertsView";

import SearchResults from "../containers/SearchResults";

import BlockingPageView from "./Pages/BlockingPageView";
import DashboardPageView from "./Pages/DashboardPageView";
import LabelsPageView from "./Pages/LabelsPageView";
import LocationsPageView from "./Pages/LocationsPageView";
import OrganizationPageView from "./Pages/OrganizationPageView";
import OrganizationsPageView from "./Pages/OrganizationsPageView";
import PipelinesPageView from "./Pages/PipelinesPageView";
import ResourcesDashboard from "./Pages/Resources/ResourcesDashboard";
import ResponderPage from "./Pages/Dashboard/Responder";
import SecurityDashboard from "./Pages/SecurityDashboard";
import SensorsPageView from "./Pages/SensorsPageView";
import SubnetsPageView from "./Pages/SubnetsPageView";
import TosView from "./Pages/TosView";
import MonitorModePageView from "./MonitorMode/MonitorModeView";
import UsersPageView2 from "./Pages/UsersPageView2";
import SingleSignOnPageView from "./Pages/SingleSignOnPageView";
import NewReportPageView from "./Pages/Reporting/new";
import { entityTypes } from "./Pages/SimpleSearch/defs";
import SimpleSearchPageView, {
  defaultStartTime,
  defaultEndTime,
  getQueryFieldsByEntityName,
} from "./Pages/SimpleSearch/PageView";
// import createOverviewQuery from "./Pages/SimpleSearch/query/overview";
import createTimelineQuery from "./Pages/SimpleSearch/query/timeline";
//import createFindingQuery from "./Pages/SimpleSearch/query/findings";
import { decodeKey } from "./Components/QueryControls";
import ExecutiveSummariesPageView from "./Pages/ExecutiveSummariesPageView";
import BillingPageView from "./Pages/BillingPageView";
import FindingsView from "./Pages/Finding";
import { fullColumnFields as findingOutputFields } from "./Pages/Finding/FindingsListView";
import RulesView from "./Pages/RulesPageView";

import SummaryDashboard from "./Pages/SummaryDashboard";

import PopularReportsView from "./Pages/PopularReports";

import CloudModulesView from "./Pages/CloudModules";

import WelcomePageView from "./Pages/WelcomePageView";

import ScheduledReportsView from "./Pages/ScheduledReportsView";
import MSPAccountsPageView from "./Pages/MSPPortal/MSPAccountsPageView";
import MSPUsersPageView from "./Pages/MSPPortal/MSPUsersPageView";
import MSPBulkActionsPageView from "./Pages/MSPPortal/MSPBulkActionsPageView";
import MSPSettingsPageView from "./Pages/MSPPortal/MSPSettingsPageView";
import MSPBillingPageView from "./Pages/MSPPortal/MSPBillingPageView";
import MSPIntegrationsPageView from "./Pages/MSPPortal/MSPIntegrationsPageView";

import DevicesPageView from "./Pages/BlumiraAgent/DevicesPageView";
import InstallationPageView from "./Pages/BlumiraAgent/InstallationPageView";

import { getPageHashParams } from "utils";
import moment from "moment-timezone";

import LCTestPage from "./Pages/LCTestPageView";
import Codex from "@blumira/blu_constants";

export const childrenPageViews = [
  {
    component: WelcomePageView,
    route: "getstarted/welcome",
    roles: ["*"],
    requirements: (state) => {
      return {
        integrationSchemas: {
          namespace: "/integrationSchema",
        },
        integrations: {
          namespace: "/integration",
        },
        responseConnectors: {
          namespace: "/response/connector",
        },
        responseConnectorTypes: {
          namespace: "/response/connectorType",
        },
        rules: {
          namespace: "/rule",
          resultParams: {
            page: { count: 20000 },
          },
        },
      };
    },
  },
  {
    component: LCTestPage,
    route: "test/lc",
    roles: ["administrator"],
    requirements: (state) => {
      return {
        agents: { namespace: "/lcagent" },
        groups: { namespace: "/lcgroup" },
      };
    },
  },
  {
    component: SummaryDashboard,
    route: "dashboard/summary",
    roles: ["administrator", "manager", "analyst"],
    requirements: (state) => {
      return {
        integrations: {
          namespace: "/integration",
        },
      };
    },
  },
  {
    route: "dashboard/manager",
    component: DashboardPageView,
    roles: ["administrator", "manager"],
  },
  {
    route: "dashboard/responder",
    component: ResponderPage,
    roles: ["responder"],
    requirements: (state) => {
      return {
        findings: {
          stale: 1,
          expire: 1,
          namespace: "/finding",
          queryParams: [
            { field: "status", value: "0" },
            {
              refName: "startTime",
              field: "created",
              operator: "lt",
              negate: true,
              value: moment
                .utc()
                .add(-1, "months")
                .startOf("minute")
                .format("YYYY-MM-DDTHH:mm"),
            },
          ],
          resultParams: {
            orderBy: [{ key: "created", value: -1 }],
            outputFields: [
              "owners",
              "id",
              "created",
              "orgId",
              "priority",
              "shortId",
              "name",
              "analysis",
              "type",
              "category",
              "confirmation",
              "confirmationCompleted",
              "escalation",
              "escalationCompleted",
              "mitigation",
              "mitigationCompleted",
            ],
          },
        },
        constants: {
          namespace: "/constants",
        },
        integrations: {
          namespace: "/integration",
        },
      };
    },
  },
  {
    route: "dashboard/security",
    component: SecurityDashboard,
    roles: ["administrator", "manager", "responder", "analyst"],
    requirements: (state) => {
      return {
        savedQueries: {
          namespace: "/query",
          queryParams: [{ field: "orgId", value: state.page.payload.orgId }],
        },
      };
    },
  },
  { route: "monitor-mode", component: MonitorModePageView },
  {
    route: "settings/blocklists",
    component: BlockingPageView,
    roles: ["administrator", "manager", "responder", "analyst"],
  },
  {
    route: "settings/locations",
    component: LocationsPageView,
    roles: ["administrator", "manager"],
    requirements: (state) => {
      return {
        locations: {
          namespace: "/location",
          queryParams: [{ field: "orgId", value: state.page.payload.orgId }],
        },
      };
    },
  },
  {
    route: "settings/organization",
    component: OrganizationPageView,
    roles: ["administrator"],
    requirements: (state) => {
      const retVal = {
        org: {
          namespace: "/org",
        },
      };
      return retVal;
    },
  },
  {
    route: "settings/organizations",
    component: OrganizationsPageView,
    roles: ["administrator"],
    modes: ["admin"],
    requirements: (state) => {
      let retVal = {};
      if (state.page.payload.id1) {
        retVal = {
          orgs: {
            expire: 0,
            namespace: "/org",
            queryParams: [{ field: "id", value: state.page.payload.id1 }],
          },
          locations: {
            expire: 0,
            namespace: "/location",
            queryParams: [{ field: "orgId", value: state.page.payload.id1 }],
          },
        };
      }
      return retVal;
    },
  },
  {
    route: "settings/pipelines",
    component: PipelinesPageView,
    roles: [],
  },
  {
    route: "settings/sensors",
    component: SensorsPageView,
    roles: ["administrator", "manager"],
  },
  {
    route: "settings/tags",
    component: LabelsPageView,
    roles: ["administrator", "manager", "responder"],
  },
  {
    route: "settings/users",
    component: UsersPageView2,
    roles: ["administrator", "manager"],
    requirements: (state) => {
      return {
        locations: {
          namespace: "/location",
          queryParams: [{ field: "orgId", value: state.page.payload.orgId }],
        },
      };
    },
  },
  {
    route: "settings/singlesignon",
    component: SingleSignOnPageView,
    roles: ["administrator"],
    requirements: (state) => {
      return {
        ssoconnection: {
          namespace: "/ssoconnection",
        },
      };
    },
  },
  {
    route: "settings/cloudmodules",
    component: CloudModulesView,
    roles: ["administrator", "manager"],
    requirements: (state) => {
      return {
        integrations: {
          namespace: "/integration",
        },
        integrationSchemas: {
          namespace: "/integrationSchema",
        },
        responseConnectors: {
          namespace: "/response/connector",
        },
        responseConnectorTypes: {
          namespace: "/response/connectorType",
        },
        orgColumnStats: {
          namespace: "/orgColumnStats",
          queryParams: [{ field: "orgId", value: state.page.payload.orgId }],
        },
      };
    },
  },
  {
    route: "settings/billing",
    component: BillingPageView,
    roles: ["administrator", "manager"],
    markets: [0, 30, 50], // direct, self-service, referral
  },
  {
    route: "reporting/events",
    redirect: "reporting/builder",
  },
  {
    route: "reporting/findings",
    component: FindingsView,
    roles: ["administrator", "manager", "responder", "analyst"],
    requirements: (state) => {
      const retVal = {
        queries: {
          namespace: "/query",
          queryParams: [{ field: "namespace", value: "finding" }],
        },
        findings: {
          stale: 1,
          expire: 1,
          namespace: "/finding",
          queryParams: [
            {
              refName: "startTime",
              field: "created",
              operator: "gt",
              value: moment
                .utc()
                .add(-1, "months")
                .startOf("minute")
                .format("YYYY-MM-DDTHH:mm"),
            },
            {
              refName: "endTime",
              field: "created",
              operator: "lt",
              value: moment
                .utc()
                .endOf("minute")
                .format("YYYY-MM-DDTHH:mm:ss.SSS999"),
            },
          ],
          resultParams: {
            outputFields: findingOutputFields,
            page: { count: 20000 },
          },
        },
        users: {
          namespace: "/user",
        },
        rules: {
          namespace: "/rule",
          resultParams: {
            page: { count: 10000 },
          },
        },
        conditions: {
          namespace: "/condition",
          resultParams: {
            page: { count: 5000 },
          },
        },
        integrations: {
          namespace: "/integration",
        },
      };

      if (state.page.payload.id1) {
        retVal.matches = {
          namespace: "/match",
          expire: 0,
          queryParams: [{ field: "findingId", value: state.page.payload.id1 }],
          resultParams: { outputFields: ["id", "ruleId", "conditionId"] },
        };
        retVal.finding = {
          namespace: "/finding",
          expire: 0,
          queryParams: [{ field: "id", value: state.page.payload.id1 }],
        };
      }

      return retVal;
    },
  },
  {
    route: "reporting/popularreports",
    component: PopularReportsView,
    roles: ["administrator", "manager", "responder", "analyst"],
    requirements: (state) => {
      return {
        savedQueries: {
          namespace: "/query",
          queryParams: [{ field: "namespace", operator: "eq", value: "bq" }],
        },
      };
    },
  },
  {
    route: "reporting/results",
    component: SearchResults,
    roles: ["administrator", "manager", "responder", "analyst"],
  },
  {
    route: "reporting/scheduledreports",
    component: ScheduledReportsView,
    roles: ["administrator", "manager", "responder", "analyst"],
    requirements: (state) => {
      return {
        reports: {
          namespace: "/report",
        },
        savedQueries: {
          expire: 1,
          namespace: "/query",
          queryParams: [
            { field: "namespace", operator: "in", value: ["finding", "bq"] },
          ],
        },
      };
    },
  },
  {
    route: "investigate/search",
    component: SimpleSearchPageView,
    roles: ["administrator", "manager", "responder", "analyst", "superadmin"],
    requirements: (state) => {
      const pageHashParams = getPageHashParams();

      const retVal = {};
      if (pageHashParams.search) {
        retVal["timelineQuery"] = createTimelineQuery(
          moment(Math.floor(pageHashParams.start || defaultStartTime)),
          moment(Math.ceil(pageHashParams.end || defaultEndTime)),
          pageHashParams.search,
          getQueryFieldsByEntityName(entityTypes.map((eT) => eT.name))
        );
        // Note: commenting out findings and overview query.  Remove this comment to display in chart

        // retVal["overviewQuery"] = createOverviewQuery(
        //   moment(Math.floor(pageHashParams.start || defaultStartTime)),
        //   moment(Math.ceil(pageHashParams.end || defaultEndTime)),
        //   pageHashParams.search,
        //   getQueryFieldsByEntityName(entityTypes.map((eT) => eT.name))
        // );

        //retVal["findings"] = createFindingQuery(moment(), "month");
      }

      return retVal;
    },
  },
  {
    route: "reporting/builder",
    component: NewReportPageView,
    roles: ["administrator", "manager", "responder", "analyst"],
    requirements: (state) => {
      const overloadFields = [];
      const toAddQPs = [];
      const toAddTypes = [];
      var appliedCreated = false;
      if (state.location.query) {
        Object.keys(state.location.query).forEach((k) => {
          const qp = { ...decodeKey(k), value: state.location.query[k] };
          if (!overloadFields.includes(qp.field) && qp.field !== "created") {
            // do not list created in outputFields
            overloadFields.push(qp.field);
          }
          if (qp.field === "created") {
            if (qp.operator === "lt") {
              qp.refName = "endTime";
            } else if (qp.operator === "gt") {
              appliedCreated = true;
              qp.refName = "startTime";
            }
          }

          if (qp.field === "type") {
            toAddTypes.push(qp.value);
          } else {
            toAddQPs.push(qp);
          }
        });
      }
      if (appliedCreated === false) {
        // in case of emergency, break comment block //
        /*
        toAddQPs.push({
          refName: "endTime",
          field: "created",
          operator: "lt",
          value: moment
            .utc()
            .endOf("minute")
            .format("YYYY-MM-DDTHH:mm:ss.SSS999"),
        })
        */
        toAddQPs.push({
          refName: "startTime",
          field: "created",
          operator: "gt",
          value: moment
            .utc()
            .add(-4, "hours")
            .startOf("minute")
            .format("YYYY-MM-DDTHH:mm"),
        });
      }

      // get list of default fields depending on type(s)
      toAddTypes.forEach((aT) => {
        if (Codex.constant.api.bq.type[aT]) {
          Codex.constant.api.bq.type[aT].core_columns.forEach((f) => {
            if (!overloadFields.includes(f)) {
              overloadFields.push(f);
            }
          });
        }
      });

      return {
        savedQueries: {
          namespace: "/query",
          queryParams: [
            { field: "namespace", operator: "eq", value: "bq" },
            { field: "uiVisible", operator: "eq", value: true },
          ],
          resultParams: {
            orderBy: [{ key: "name", value: 1 }],
          },
        },
        activeQuery: {
          namespace: "/bq",
          queryParams: [
            ...toAddQPs,
            {
              refName: "selectedLogTypes",
              field: "type",
              operator: "in",
              value: toAddTypes,
            },
          ],
          resultParams: {
            outputFields: overloadFields,
            orderBy: [{ key: "Timestamp", value: -1 }],
          },
        },
        orgLogTypes: {
          namespace: "/logtype",
          resultParams: {
            outputFields: ["name"],
          },
        },
      };
    },
  },

  {
    route: "reporting/executivesummaries",
    component: ExecutiveSummariesPageView,
    roles: ["administrator", "manager", "responder", "analyst"],
  },
  /*
  {
    route: 'reporting/legacy',
    component: ReportPageView,
    roles: ['administrator', 'manager', 'responder', 'analyst'],
    requirements: (state) => {
      return {
        logTypes: {
          namespace: '/logtype',
          stale:0,
        },
        columns: {
          namespace: '/column',
          stale:0,
        },
        savedQueries: {
          namespace: '/dataquery',
          queryParams: [
            {field: 'orgId', value: state.page.payload.orgId},
            {field:'dataSource', value: 'bq'}
          ],
          stale:1,
          expire: 1
        },
      }
    }
  },
  */
  {
    route: "settings/rules",
    component: RulesView,
    roles: ["administrator", "manager", "responder", "analyst"],
    requirements: (state) => {
      return {
        rules: {
          namespace: "/rule",
          resultParams: {
            page: { count: 10000 },
          },
        },
        conditions: {
          namespace: "/condition",
          resultParams: {
            page: { count: 5000 },
          },
        },
        actions: {
          namespace: "/action",
        },
        findingTypes: {
          namespace: "/constant/finding_type",
        },
        users: {
          namespace: "/user",
        },
        allowlistEntry: {
          namespace: "/allowlistentry",
          stale: 1,
          expire: 1,
        },
        org: {
          namespace: "/org",
        },
      };
    },
  },
  {
    route: "user/alertsettings",
    component: MyAlertsView,
  },
  {
    route: "user/myaccount",
    component: MyAccountView,
  },
  {
    route: "user/tos",
    component: TosView,
  },
  {
    route: "mspportal/accounts",
    component: MSPAccountsPageView,
    roles: ["administrator"],
    markets: [20],
    requirements: (state) => {
      return {
        users: {
          namespace: "/user",
        },
      };
    },
  },
  {
    route: "mspportal/users",
    component: MSPUsersPageView,
    roles: ["administrator"],
    markets: [20],
  },
  {
    route: "mspportal/bulk",
    component: MSPBulkActionsPageView,
    roles: ["administrator"],
    markets: [20],
  },
  {
    route: "mspportal/settings",
    component: MSPSettingsPageView,
    roles: ["administrator"],
    markets: [20],
  },
  {
    route: "mspportal/billing",
    component: MSPBillingPageView,
    roles: ["superadmin"], // TODO: Change this back to "administrator" when we are ready to GA this new feature
    markets: [20],
    requirements: (state) => {
      return {
        mspBillingPlan: {
          namespace: "/mspbillingplan",
        },
        usageReports: {
          namespace: "/usagereport",
        },
        billingPlanTemplates: {
          namespace: "/mspbillingplantemplate",
        },
        billingPlanRamp: {
          namespace: "/mspbillingplanramp",
        },
      };
    },
  },
  {
    route: "mspportal/mspintegrations",
    component: MSPIntegrationsPageView,
    roles: ["administrator"],
    markets: [20],
    requirements: (state) => {
      return {
        mspIntegrations: {
          namespace: "/psaintegration",
          stale: 1,
          expire: 1,
        },
      };
    },
  },
  {
    route: "blumiraagent/devices",
    component: DevicesPageView,
    markets: [0, 20, 30], // direct, MSP, self-service
    requirements: (state) => {
      return {
        devices: {
          namespace: "/lcagent",
          stale: 1,
          expire: 1,
          resultParams: {
            page: { count: 20000 },
          },
        },
        users: {
          namespace: "/user",
        },
        failedInstalls: {
          namespace: "/lcfailedinstall",
        },
      };
    },
  },
  {
    route: "blumiraagent/installation",
    component: InstallationPageView,
    markets: [0, 20, 30], // direct, MSP, self-service
    roles: ["administrator", "manager"],
    requirements: (state) => {
      return {
        keys: {
          namespace: "/lcgroup",
        },
      };
    },
  },
  {
    route: "superadmin/subnets",
    component: SubnetsPageView,
    roles: [],
    labs: true,
    requirements: (state) => {
      return {
        subnets: {
          namespace: "/subnet",
          queryParams: [{ field: "orgId", value: state.page.payload.orgId }],
        },
      };
    },
  },
  {
    route: "superadmin/pipelines",
    component: PipelinesPageView,
    roles: [],
  },
  {
    route: "superadmin/resources",
    component: ResourcesDashboard,
    roles: [],
  },
];
