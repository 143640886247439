import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { get, isNil } from "lodash";

import moment from "moment-timezone";

import {
  Tab,
  Tabs,
  Radio,
  Button,
  RadioGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { Alert, AlertTitle } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import Request from "lib/api/Request";

import { loadPageData } from "redux/actions/Request";

import SimpleTable from "views/Components/SimpleTable";
import LicenseRestriction from "views/Components/License";

import { BlumiraAgentActivationBanner } from "views/Components/BlumiraAgent/BlumiraAgentActivationBanner";
import { ActivationConfirmationDialog } from "views/Components/BlumiraAgent/ActivationConfirmationDialog";

import { isOrganizationChildOfNFR, replaceTransmissionVariables } from "utils";

import {
  deviceTableFilters,
  deviceDetailsFields,
  getErrorMessageToSet,
  getDevicesTableColumns,
  sendEmailToSalesTeam,
  setLogShippingFeature,
  getPageActionToDispatch,
} from "./utils";

import { hasLegacyLicense } from "utils";
import { getChildOrgs } from "../MSPPortal/utils";

import {
  Root,
  DevicesDialog,
  FailedInstallDialog,
  rootClasses,
  dialogClasses,
} from "./devicesPageStyles";

const DevicesPageView = (props) => {
  const [tabValue, setTabValue] = useState("1");
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [deviceBeingViewed, setDeviceBeingViewed] = useState({});
  const [isDeviceIsolated, setIsDeviceIsolated] = useState(false);
  const [isActivatingAgent, setIsActivatingAgent] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All devices");
  const [wasEmailSuccessful, setWasEmailSuccessful] = useState(false);
  const [hasIsolationChanged, setHasIsolationChanged] = useState(false);
  const [viewingTransmissions, setViewingTransmissions] = useState([]);
  const [failedInstalls, setFailedInstalls] = useState([]);
  const [isRemoveDeviceDialogOpen, setIsRemoveDeviceDialogOpen] =
    useState(false);
  const [isDeviceDetailDialogOpen, setIsDeviceDetailDialogOpen] =
    useState(false);
  const [isToggleIsolationDialogOpen, setIsToggleIsolationDialogOpen] =
    useState(false);
  const [
    isActivationConfirmationDialogOpen,
    setIsActivationConfirmationDialogOpen,
  ] = useState(false);
  const [isDeviceExcluded, setIsDeviceExcluded] = useState(false);
  const [isFailedInstallModalOpen, setIsFailedInstallModalOpen] =
    useState(false);

  useEffect(() => {
    if (props.ready) {
      if (props.query && props.query.isIsolated)
        handleApplyFilter({}, "Isolated");
      else setDisplayData(props.devices);

      if (
        props.failedInstalls != undefined &&
        props.failedInstalls.length != 0
      ) {
        setFailedInstalls(props.failedInstalls);
      } else setFailedInstalls([]);
    }
  }, [props.ready, props.devices, props.query]);

  const handleApplyFilter = (event, newSelectedFilter) => {
    // I'm thinking this is where we can filter data based on the preset
    // filters defined in deviceTableFilters in the constants file
    const targetFilter = deviceTableFilters.find(
      (filter) => newSelectedFilter === filter.label
    );

    const filteredDataToDisplay = targetFilter.filterMethod(props.devices);

    setDisplayData(filteredDataToDisplay);

    setSelectedFilter(targetFilter.label);
  };

  const handleCloseDeviceDetailDialog = () => {
    setTabValue("1");
    setIsSaving(false);
    setErrorMessage("");
    setHasIsolationChanged(false);
    setIsDeviceDetailDialogOpen(false);
  };

  const handleCloseFailedInstallDialog = () => {
    setIsFailedInstallModalOpen(false);
  };

  const handleRemoveDevice = () => {
    deviceBeingViewed
      .delete()
      .then(() => {
        props.reload(true);
        toggleRemoveDeviceDialog();
        handleCloseDeviceDetailDialog();
        setErrorMessage("");
      })
      .catch((e) => {
        // TODO(ENG-4133): We want this error to surface on the dialog, not dismiss it
        // But for now to improve user experiene and make error more visable
        // we are going to dismiss it.
        toggleRemoveDeviceDialog();
        setErrorMessage("Oops, we had a problem deleting that device.");
      });
  };

  const handleSaveDeviceDetails = () => {
    setIsSaving(true);
    deviceBeingViewed.set({
      isIsolated: isDeviceIsolated,
      isExcluded: isDeviceExcluded,
    });
    deviceBeingViewed
      .update()
      .then(() => {
        handleCloseDeviceDetailDialog();
        setIsSaving(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsSaving(false);
        setErrorMessage("Oops, we had a problem saving that device.");
      });
  };

  const handleOpenFailedInstallDialog = () => {
    // TODO: sounds like maybe we need to make a call
    // to LC to get the device details here
    setIsLoading(true);
    setIsFailedInstallModalOpen(true);

    setFailedInstalls(failedInstalls);
    setIsLoading(false);
  };

  // begin funcs for context menu in SimpleTable
  // we get access to model through contextMenu arg
  const handleOpenDeviceDetailDialog = ({ model }) => {
    // TODO: sounds like maybe we need to make a call
    // to LC to get the device details here
    setIsSaving(false);
    setIsLoading(true);
    setIsDeviceDetailDialogOpen(true);

    model
      .read()
      .then(async (extendedDeviceModel) => {
        setDeviceBeingViewed(extendedDeviceModel);

        const transmissionQueryParams = [{ field: "modelId", value: model.id }];

        const transmissionResultParams = {
          orderBy: [{ key: "created", value: -1 }],
        };

        const transmissionReq = new Request(
          "/transmission",
          transmissionQueryParams,
          transmissionResultParams
        );

        const newViewingTransmissions = await transmissionReq.get();

        setViewingTransmissions(newViewingTransmissions);

        setIsLoading(false);

        // set isolation to easily toggle with radio
        setIsDeviceIsolated(extendedDeviceModel.isIsolated);
        // set AHI exclusion to easily toggle with checkbox
        setIsDeviceExcluded(extendedDeviceModel.isExcluded);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const toggleRemoveDeviceDialog = (contextMenu = {}) => {
    const { model } = contextMenu;
    // if we have a model, the action is from the
    // context menu and we need a model to delete
    if (model) setDeviceBeingViewed(model);

    setIsRemoveDeviceDialogOpen(!isRemoveDeviceDialogOpen);
  };

  const toggleIsolationConfirmationDialog = (contextMenu = {}) => {
    const { model } = contextMenu;
    if (model) setDeviceBeingViewed(model);

    setIsToggleIsolationDialogOpen(!isToggleIsolationDialogOpen);
  };

  const handleIsolationToggle = () => {
    deviceBeingViewed.set({ isIsolated: !deviceBeingViewed.isIsolated });
    deviceBeingViewed.update();
    toggleIsolationConfirmationDialog();
  };

  const isIsolationDisabled = ({ model = {} }) => {
    return !model.alive;
  };

  const isRemoveDeviceDisabled = () => {
    return !props.hasAgentRemovalPrivileges;
  };

  const handleViewUnresolvedFindings = ({ model }) => {
    const deviceNameForFilter = get(model, ["hostname"], "");

    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: model.orgId,
        toplevel: "reporting",
        secondlevel: "findings",
      },
      query: {
        status: 0,
        deviceName: deviceNameForFilter,
      },
    });
  };

  const handleViewDeviceLogs = ({ model }) => {
    const actionToDispatch = getPageActionToDispatch(model);

    props.dispatch(actionToDispatch);
  };
  // end funcs for context menu in SimpleTable

  const handleIsolationToggleFromRadio = () => {
    setHasIsolationChanged(true);
    setIsDeviceIsolated(!isDeviceIsolated);
  };

  const handleDeviceExclusionFromCheckbox = () => {
    setHasIsolationChanged(true);
    setIsDeviceExcluded(!isDeviceExcluded);
  };

  const toggleActivationConfirmationDialog = () => {
    setWasEmailSuccessful(false);
    setIsActivationConfirmationDialogOpen(!isActivationConfirmationDialogOpen);
  };

  const handleActivateDevicesFeature = async () => {
    const { isOrgAChildOrg, isOrgAParentOrg, currentOrg, currentUser } = props;

    const errorMessageToSet = getErrorMessageToSet(currentOrg);

    try {
      if (isOrgAChildOrg || isOrgAParentOrg) {
        setIsActivatingAgent(true);
        await setLogShippingFeature(
          currentOrg,
          currentUser.email,
          props.doesAnyOfParentsChildrenHaveLegacyLicense
        );
        setIsActivatingAgent(false);
        setErrorMessage("");

        // reload page for license feature updates
        window.location.reload();
      } else {
        try {
          await sendEmailToSalesTeam(currentOrg.name);
          setErrorMessage("");
          setWasEmailSuccessful(true);
        } catch (e) {
          setErrorMessage(errorMessageToSet);
          setWasEmailSuccessful(false);
        }
      }
    } catch (e) {
      // if anything goes wrong notify the user and reset
      setErrorMessage(errorMessageToSet);
      setIsActivatingAgent(false);
    }
  };

  const handleActivateClick = async () => {
    const { isOrgAChildOrg, isOrgAParentOrg } = props;

    // allow MSP NFR admins to freely grant access to
    // this feature, else present confirmation dialog
    if (isOrgAChildOrg || isOrgAParentOrg) {
      handleActivateDevicesFeature();
    } else {
      toggleActivationConfirmationDialog();
    }
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const renderCreatedCell = (column, model) => {
    const timeValue = model[column.field];

    if (!timeValue) {
      return "Unknown date";
    }
    return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
  };

  const renderActivityCell = (column, model) => {
    const kwargsObject = model["kwargs"] ? model["kwargs"] : {};
    const transmissionType = model[column.field];

    // use linkArg prop to create link based
    // on kwargs to route to other in-app pages
    let linkArg;

    // for this transmission activity string in particular
    // we want to route to a finding's details if we find an id
    const findingIdKwargValue = kwargsObject["finding_id"];

    if (findingIdKwargValue) {
      linkArg = {
        linkText: "View finding.",
        linkTo: {
          type: "PAGE",
          payload: {
            orgId: props.currentOrg.id,
            toplevel: "reporting",
            secondlevel: "findings",
            id1: findingIdKwargValue,
          },
        },
      };
    }

    const valMap = {
      is_isolated: {
        true: "isolated",
        false: "unisolated",
      },
    };

    const user = props.users.find((u) => u.id === kwargsObject.user_id);

    const stringToRender = replaceTransmissionVariables({
      kwargs: {
        ...kwargsObject,
        user_id: user ? user.email : "an administrator",
      },
      valMap: valMap,
      transmissionType,
      linkArg,
    });

    return stringToRender;
  };

  const renderRefineSearchSection = () => (
    <div className={rootClasses.quickFilterContainer}>
      <span className={rootClasses.quickFilterLabel}>Quick Filters</span>
      <ToggleButtonGroup
        exclusive
        value={"Quick filters"}
        onChange={handleApplyFilter}
        aria-label="Quick filters for devices table"
      >
        {deviceTableFilters.map(({ label }, index) => (
          <ToggleButton
            key={`${label}-${index}`}
            value={label}
            aria-label={`Apply ${label} quick filter`}
            className={rootClasses.quickFilterButton}
            selected={label === selectedFilter ? true : false}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );

  // TODO: repurpose for upsale
  const renderDisallow = () => {
    return;
  };

  const renderAllow = (children) => {
    return children;
  };

  const renderBanner = (children) => {
    return children;
  };

  const hideBanner = () => {
    return null;
  };

  const contactLink =
    props.currentOrg?.market === 20
      ? "https://blumira.help/partners"
      : "https://blumira.help/help";

  return (
    <Root>
      {failedInstalls.length > 0 && (
        <Alert severity={"warning"} className={rootClasses.errorBanner}>
          Some agent installations failed due to exhausted installation keys.
          <Button
            variant={"text"}
            onClick={handleOpenFailedInstallDialog}
            className={rootClasses.addAgentButton}
          >
            See failed agent installs.
          </Button>
        </Alert>
      )}
      {errorMessage && (
        <Alert
          className={rootClasses.errorBanner}
          severity="error"
          action={
            <a
              className={rootClasses.errorAction}
              rel={"noopener noreferrer"}
              target={"_blank"}
              href={contactLink}
            >
              CONTACT
            </a>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      <LicenseRestriction
        requires="lcagent.create"
        renderAllow={hideBanner}
        renderDisallow={renderBanner}
      >
        <BlumiraAgentActivationBanner
          isOrgAParentOrg={props.isOrgAParentOrg}
          isOrgAChildOrg={props.isOrgAChildOrg}
          isActivatingAgent={isActivatingAgent}
          onActivateClick={handleActivateClick}
        />
      </LicenseRestriction>

      <LicenseRestriction
        requires="lcagent.create"
        renderAllow={renderAllow}
        renderDisallow={renderDisallow}
      >
        <SimpleTable
          isNorthStar
          data={displayData}
          isFetching={!props.ready}
          columns={getDevicesTableColumns(
            props.license.hasFeature("AUTOBOT"),
            props.license.hasFeature("MACANDLINUX")
          )}
          emptyText={
            <span>
              No agents have shipped logs yet
              {/* 
                                FYI: hiding this link until GA
                                <a 
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                    href={blumiraAgentSetupUrl} 
                                    className={classes.leftMarginLink}
                                >
                                    See Blumira Agent setup guide <OpenInNewIcon/>
                                </a> 
                            */}
            </span>
          }
          quickFilterComponent={renderRefineSearchSection()}
          contextMenuItems={[
            {
              text: "Device details",
              onClick: handleOpenDeviceDetailDialog,
              datacy: "devicesContextMenuItemViewDetails",
            },
            {
              text: "View unresolved findings",
              onClick: handleViewUnresolvedFindings,
            },
            {
              text: "View device logs",
              onClick: handleViewDeviceLogs,
            },
            {
              text: "Delete agent",
              onClick: toggleRemoveDeviceDialog,
              isDisabled: isRemoveDeviceDisabled,
              disabledTooltip:
                "You do not have sufficient permissions to delete this device.",
              datacy: "devicesContextMenuItemDeleteDevice",
            },
          ]}
        />
      </LicenseRestriction>
      <FailedInstallDialog
        open={isFailedInstallModalOpen}
        onClose={handleCloseFailedInstallDialog}
        fullWidth={true}
        datacy={"failedInstallDetailsModal"}
      >
        <div className={dialogClasses.dialogTitle}>
          <DialogTitle>Installed agents with no installation key</DialogTitle>
          <CloseIcon
            className={dialogClasses.close}
            onClick={handleCloseFailedInstallDialog}
          />
        </div>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {isLoading ? (
            <div className={dialogClasses.loadingDialogContainer}>
              <CircularProgress color={"primary"} size={30} />
              <p>Loading failed installs...</p>
            </div>
          ) : (
            <SimpleTable
              isSearchHidden={true}
              data={failedInstalls}
              title={"Hosts without valid installation key"}
              columns={[
                {
                  title: "Host Name",
                  field: "hostname",
                },
                {
                  title: "Key Name",
                  field: "keyname",
                },
              ]}
            />
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions className={dialogClasses.dialogActions}>
            <div>
              <Button
                variant={"text"}
                onClick={handleCloseFailedInstallDialog}
                color={"primary"}
                datacy={"failedInstallModalCancelButton"}
              >
                Close
              </Button>
            </div>
          </DialogActions>
        )}
      </FailedInstallDialog>
      <DevicesDialog
        open={isDeviceDetailDialogOpen}
        onClose={handleCloseDeviceDetailDialog}
        fullWidth={true}
        datacy={"deviceDetailsModal"}
      >
        <div className={dialogClasses.dialogTitle}>
          <DialogTitle>Device details</DialogTitle>
          <CloseIcon
            className={dialogClasses.close}
            onClick={handleCloseDeviceDetailDialog}
          />
        </div>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {isLoading ? (
            <div className={dialogClasses.loadingDialogContainer}>
              <CircularProgress color={"primary"} size={30} />
              <p>Loading your device details...</p>
            </div>
          ) : (
            <TabContext value={tabValue}>
              <Tabs
                value={tabValue}
                textColor={"primary"}
                indicatorColor={"primary"}
                onChange={handleTabChange}
                className={dialogClasses.tabs}
              >
                <Tab label={"Details"} value={"1"} />
                <Tab label={"Activity log"} value={"2"} />
              </Tabs>
              <TabPanel value={"1"} className={dialogClasses.tabPanel}>
                {deviceDetailsFields.map((deviceField, index) => (
                  <div
                    key={`${index}-${deviceField.title}`}
                    className={dialogClasses.deviceDetailOuterContainer}
                  >
                    <div className={dialogClasses.deviceDetailInnerContainer}>
                      <p className={dialogClasses.displayNameText}>
                        {deviceField.title}
                      </p>
                    </div>
                    <div className={dialogClasses.deviceDetailInnerContainer}>
                      {deviceField.renderDetailText ? ( // renderDetailText takes precendence in the dialog
                        deviceField.renderDetailText(
                          deviceField,
                          deviceBeingViewed
                        )
                      ) : deviceField.renderValue && deviceField.icon ? (
                        <div className={dialogClasses.platformTypeModalWrap}>
                          <div className={dialogClasses.platformTypeIconWrap}>
                            {deviceField.icon(deviceField, deviceBeingViewed)}
                          </div>
                          {deviceField.renderValue(
                            deviceField,
                            deviceBeingViewed
                          )}
                        </div>
                      ) : deviceField.renderValue ? (
                        deviceField.renderValue(deviceField, deviceBeingViewed)
                      ) : (
                        <p className={dialogClasses.valueText}>
                          {deviceBeingViewed &&
                            deviceBeingViewed[deviceField.field]}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
                <div className={dialogClasses.hostIsolationContainer}>
                  <p className={dialogClasses.displayNameText}>
                    Host isolation
                  </p>
                  {isIsolationDisabled({ model: deviceBeingViewed }) && (
                    <p className={dialogClasses.warning}>
                      Isolation toggling is currently disabled as your device is
                      offline.
                    </p>
                  )}
                  <RadioGroup>
                    <FormControlLabel
                      value={false}
                      label={`Not Isolated ${
                        deviceBeingViewed.isIsolated &&
                        !deviceBeingViewed.isolationRequested
                          ? "(pending)"
                          : ""
                      }`}
                      className={dialogClasses.formControlLabel}
                      onChange={handleIsolationToggleFromRadio}
                      control={
                        <Radio
                          checked={!isDeviceIsolated}
                          datacy={"devicesNotIsolatedRadio"}
                        />
                      }
                      disabled={isIsolationDisabled({
                        model: deviceBeingViewed,
                      })}
                    />
                    <FormControlLabel
                      value={true}
                      className={dialogClasses.formControlLabel}
                      onChange={handleIsolationToggleFromRadio}
                      control={
                        <Radio
                          checked={isDeviceIsolated}
                          datacy={"devicesIsolatedRadio"}
                        />
                      }
                      disabled={isIsolationDisabled({
                        model: deviceBeingViewed,
                      })}
                      label={`Isolated ${
                        !deviceBeingViewed.isIsolated &&
                        deviceBeingViewed.isolationRequested
                          ? "(pending)"
                          : "(Block outgoing network traffic except to Blumira)"
                      }`}
                    />
                  </RadioGroup>
                </div>
                <LicenseRestriction
                  features={["AUTOBOT"]}
                  renderAllow={(children) => children}
                  renderDisallow={() => null}
                >
                  <FormControl
                    component="fieldset"
                    className={dialogClasses.ahiCheckboxWrap}
                  >
                    <FormLabel
                      component="legend"
                      className={dialogClasses.ahiCheckboxLegend}
                    >
                      Auto host isolation
                    </FormLabel>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          name="excludeDevice"
                          color="primary"
                          checked={isDeviceExcluded}
                          onChange={handleDeviceExclusionFromCheckbox}
                        />
                      }
                      label="Exclude this device from automated host isolation"
                      className={dialogClasses.ahiCheckboxLabel}
                    />
                  </FormControl>
                </LicenseRestriction>
              </TabPanel>
              <TabPanel value={"2"} className={dialogClasses.tabPanel}>
                <SimpleTable
                  isSearchHidden={true}
                  data={viewingTransmissions}
                  columns={[
                    {
                      title: "Date",
                      field: "created",
                      renderValue: renderCreatedCell,
                    },
                    {
                      title: "Activity",
                      field: "transmissionType",
                      renderValue: renderActivityCell,
                    },
                  ]}
                />
              </TabPanel>
            </TabContext>
          )}
        </DialogContent>
        {!isLoading && (
          <DialogActions className={dialogClasses.dialogActions}>
            <Button
              variant={"text"}
              onClick={toggleRemoveDeviceDialog}
              className={dialogClasses.errorTextButton}
              disabled={!props.hasAgentRemovalPrivileges}
              datacy={"deviceDetailModalDeleteButton"}
            >
              <DeleteIcon /> Delete device
            </Button>
            <div>
              <Button
                variant={"text"}
                onClick={handleCloseDeviceDetailDialog}
                color={"primary"}
                datacy={"deviceDetailModalCancelButton"}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveDeviceDetails}
                variant={"contained"}
                color={"primary"}
                disabled={!hasIsolationChanged}
                datacy={"deviceDetailModalSaveButton"}
              >
                {isSaving ? "Saving..." : "Save changes"}
              </Button>
            </div>
          </DialogActions>
        )}
      </DevicesDialog>
      <DevicesDialog
        open={isRemoveDeviceDialogOpen}
        onClose={toggleRemoveDeviceDialog}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          You are about to remove this device. This device's agent will be
          un-enrolled and no longer ship logs to Blumira. Existing logs will be
          retained in Blumira.
        </DialogContent>
        <DialogActions>
          <Button
            variant={"text"}
            onClick={toggleRemoveDeviceDialog}
            color={"primary"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveDevice}
            variant={"contained"}
            className={dialogClasses.errorContainedButton}
            disabled={!props.hasAgentRemovalPrivileges}
          >
            Remove this device
          </Button>
        </DialogActions>
      </DevicesDialog>
      <DevicesDialog
        open={isToggleIsolationDialogOpen}
        onClose={toggleIsolationConfirmationDialog}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          You are about to{" "}
          {deviceBeingViewed.isIsolated ? "release" : "isolate"} this device.
        </DialogContent>
        <DialogActions>
          <Button
            variant={"text"}
            onClick={toggleIsolationConfirmationDialog}
            color={"primary"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleIsolationToggle}
            variant={"contained"}
            className={dialogClasses.errorContainedButton}
            datacy={"deviceIsolationConfirmationButton"}
          >
            {deviceBeingViewed.isIsolated ? "Release" : "Isolate"} this device
          </Button>
        </DialogActions>
      </DevicesDialog>
      <ActivationConfirmationDialog
        wasEmailSuccessful={wasEmailSuccessful}
        open={isActivationConfirmationDialogOpen}
        onClose={toggleActivationConfirmationDialog}
        handleActivateDevicesFeature={handleActivateDevicesFeature}
      />
    </Root>
  );
};

DevicesPageView.defaultProps = {
  users: [],
};

const mapStateToProps = (state) => {
  const { session } = state;
  const query = get(state, ["location", "query"], {});
  const currentUser = state.session.settings.user;
  const currentOrgId = state.location.payload.orgId;

  // get roles of current user to determine if they are
  // *only* a responder, which restricts deleting
  const { orgRoles } = currentUser;

  // we only need roles set on the current org
  const currentUserOrgRoles = orgRoles.filter(
    ({ orgId }) => orgId === currentOrgId
  );

  // after getting current org roles, find any allowed role
  // for allowing access to restricted features (deleting agent)
  const hasAgentRemovalPrivileges =
    currentUser.superadmin ||
    currentUserOrgRoles.some(
      ({ roleId }) =>
        !![10, 20, 30].find((allowedRole) => allowedRole === roleId)
    );

  const currentOrgJSON = state.session.settings.userOrgs.find(
    ({ id }) => id === currentOrgId
  );

  const isOrgAChildOrg = isOrganizationChildOfNFR(currentOrgJSON);
  const isOrgAParentOrg =
    currentOrgJSON &&
    currentOrgJSON.market === 20 &&
    isNil(currentOrgJSON.parentId);

  // Determine if any of the child org's parent's children have a legacy license edition
  const currentOrg = session.settings.userOrgs.find(
    ({ id }) => id === currentOrgId
  );
  const currentOrgsParent = session.settings.userOrgs.find(
    ({ id }) => id === currentOrg?.parentId
  );
  const orgListToFilter = get(session, `settings.userOrgs`, []);
  const parentsChildOrgsList = getChildOrgs(
    orgListToFilter,
    currentOrgsParent?.id
  );
  const doesAnyOfParentsChildrenHaveLegacyLicense =
    parentsChildOrgsList.some(hasLegacyLicense);

  return {
    query,
    currentUser,
    isOrgAChildOrg,
    isOrgAParentOrg,
    license: state.license,
    currentOrg: currentOrgJSON,
    hasAgentRemovalPrivileges: !!hasAgentRemovalPrivileges, // bool to determine if user has permission to remove devices
    doesAnyOfParentsChildrenHaveLegacyLicense,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reload: (force) => dispatch(loadPageData(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPageView);
