import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import {
  Link,
  Card,
  Alert,
  Table,
  Radio,
  Switch,
  Button,
  Tooltip,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Accordion,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  TableContainer,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  CircularProgress,
  Paper,
} from "@mui/material";

import { getOrgs } from "redux/actions/Org";
import { loadPageData } from "redux/actions/Request";

import { MaxioAPI } from "lib/api/MaxioAPI";
import { MSPStripeAPI } from "lib/api/MSPStripeAPI";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { tiers } from "./constants";

import { Root, classes, StyledDialog, dialogClasses } from "./styles";

import MSPBillingContactsDialog from "views/Components/MSP/MSPBillingContactsDialog";
import MSPUsageReportsDialog from "views/Components/MSP/MSPUsageReportsDialog";
import MSPCustomRampFields from "views/Components/MSP/MSPCustomRampFields";
import MSPInvoicesDialog from "views/Components/MSP/MSPInvoicesDialog";

import { MSPBillingPlan } from "lib/models/MSPBillingPlan";
import { MSPBillingPlanRamp } from "lib/models/MSPBillingPlanRamp";
import { User } from "lib/models/User";

const API = {
  stripe: new MSPStripeAPI(),
  maxio: new MaxioAPI(),
};

const createEmailArray = (emailString) => {
  if (!emailString) {
    return [];
  }

  const emails = emailString.split(",").map((email) => email.trim());
  const emailArray = emails.map((email, index) => ({
    id: index + 1,
    email: email,
  }));

  return emailArray;
};

const mergeTiersWithTemplates = (tiers, templates = []) => {
  return tiers.map((tier) => {
    const matchingTemplate = templates.find(
      (template) => template?.tier === tier.tier
    );
    return {
      ...tier,
      ...matchingTemplate,
    };
  });
};

const MSPBillingPageView = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [emailArray, setEmailArray] = useState([]);
  const [emailString, setEmailString] = useState("");
  const [selectedTier, setSelectedTier] = useState("");
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isBillingContactsDialogOpen, setIsBillingContactsDialogOpen] =
    useState(false);
  const [isUsageReportsDialogOpen, setIsUsageReportsDialogOpen] =
    useState(false);
  const [isInvoicesDialogOpen, setIsInvoicesDialogOpen] = useState(false);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const [currentAgreement, setCurrentAgreement] = useState(false);
  const [signedAgreementUser, setSignedAgreementUser] = useState({});
  const [mspBillingPlan, setMSPBillingPlan] = useState({});
  const [shouldShowAlert, setShouldShowAlert] = useState(false);
  const [
    didUserAgreeToTermsAndConditions,
    setDidUserAgreeToTermsAndConditions,
  ] = useState(false);
  const [isSubmitOrderButtonDisabled, setIsSubmitOrderButtonDisabled] =
    useState(true);
  const [monthOne, setMonthOne] = useState("");
  const [monthTwo, setMonthTwo] = useState("");
  const [monthThree, setMonthThree] = useState("");
  const [monthFour, setMonthFour] = useState("");
  const [monthFive, setMonthFive] = useState("");
  const [monthSix, setMonthSix] = useState("");
  const [overrideMin, setOverrideMin] = useState("");
  const [isCustomAgreementButtonDisabled, setIsCustomAgreementButtonDisabled] =
    useState(true);
  const [customTermStart, setCustomTermStart] = useState(
    moment(new Date()).utc().tz(moment.tz.guess(true))
  );
  const [customTermEnd, setCustomTermEnd] = useState(
    moment(new Date()).utc().tz(moment.tz.guess(true)).add(364, "days")
  );
  const [customTermType, setCustomTermType] = useState("standard");
  const [mergedTiers, setMergedTiers] = useState([]);
  const [mspCustomRamp, setMSPCustomRamp] = useState({});
  const [currentCustomRamp, setCurrentCustomRamp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // eslint-disable-line no-unused-vars
  const [currentPlanData, setCurrentPlanData] = useState(null);
  const [isLaunchingStripePortal, setIsLaunchingStripePortal] = useState(false);
  const [currentInvoiceData, setCurrentInvoiceData] = useState([]);
  const [isAgreementConfirmationOpen, setIsAgreementConfirmationOpen] =
    useState(false);
  const [currentCustomerData, setCurrentCustomerData] = useState(null);

  const renderFormattedDate = (timeValue) => {
    if (!timeValue) {
      return "Unknown date";
    }
    return moment.utc(timeValue).tz(moment.tz.guess(true)).format("LL");
  };

  const renderFormattedPrice = (priceValue) => {
    if (!priceValue) {
      return "Unknown price";
    }

    if (String(priceValue).includes(".")) {
      return Number(priceValue).toFixed(2);
    }

    return Number(priceValue);
  };

  const fetchAndConfigureBillingContacts = async () => {
    if (props.currentOrg.maxioCustomerId) {
      const customer = await API.maxio.getCustomer(
        props.currentOrg.maxioCustomerId
      );
      const activeCustomer = customer.data;

      const existingString = activeCustomer.email;
      const newEmailArray = createEmailArray(existingString);

      setEmailString(existingString);
      setEmailArray(newEmailArray);
    }
  };

  const fetchAndSetCustomerInvoices = async () => {
    let activeCustomer = null;
    let activeInvoices = null;
    let activeStripeCustomer = null;

    if (!props.currentOrg) return;

    if (
      props.currentOrg.maxioCustomerId &&
      props.currentOrg.maxioCustomerId !== undefined &&
      props.currentOrg.maxioCustomerId !== "undefined"
    ) {
      const customer = await API.maxio.getCustomer(
        props.currentOrg.maxioCustomerId
      );
      activeCustomer = customer.data;
      setCurrentCustomerData(activeCustomer);
    } else {
      try {
        const {
          data: { stripe_id, maxio_id },
        } = await API.maxio.lookupCustomer({
          currentOrgId: props.currentOrg.id,
        });
        if (stripe_id === null || stripe_id === "null") {
          props.currentOrg.set({
            maxioCustomerId: String(maxio_id),
          });
        } else {
          props.currentOrg.set({
            stripeCustomerId: String(stripe_id),
            maxioCustomerId: String(maxio_id),
          });
        }
        await props.currentOrg.update();
        const customer = await API.maxio.getCustomer(
          props.currentOrg.maxioCustomerId
        );
        activeCustomer = customer.data;
        setCurrentCustomerData(activeCustomer);
      } catch (e) {
        // do nothing
      }
    }

    if (
      props.currentOrg.stripeCustomerId &&
      props.currentOrg.stripeCustomerId !== null &&
      props.currentOrg.stripeCustomerId !== "null"
    ) {
      const {
        data: { customer },
      } = await API.stripe.getCustomer(props.currentOrg.stripeCustomerId);
      activeStripeCustomer = customer;
      setCurrentPlanData(activeStripeCustomer);
    }

    if (!props.currentOrg.maxioCustomerId) {
      const {
        data: { id },
      } = await API.maxio.createCustomer({
        name: props.currentOrg.name,
        email: props.currentUser.email,
        domain: props.currentOrg.webUrl,
        is_active: true,
        default_enable_cc_payment: true,
        default_enable_ach_payment: true,
        autopay_enrollment: "manual_enroll",
        c_blumira_platform_id: props.currentOrg.id,
        c_customer_type: "MSP Directed",
        c_partner_type: "MSP",
        c_partner_name: props.currentOrg.name,
      });

      props.currentOrg.set({
        maxioCustomerId: String(id),
      });
      await props.currentOrg.update();

      const customer = await API.maxio.getCustomer(
        props.currentOrg.maxioCustomerId
      );
      activeCustomer = customer.data;
      setCurrentCustomerData(activeCustomer);
    }

    const existingString = activeCustomer.email;
    const newEmailArray = createEmailArray(existingString);

    setEmailString(existingString);
    setEmailArray(newEmailArray);

    const invoices = await API.maxio.getInvoices(
      props.currentOrg.maxioCustomerId
    );
    activeInvoices = invoices.data.reverse();
    setCurrentInvoiceData(activeInvoices);
  };

  const fetchUser = async () => {
    new User({ id: props.mspBillingPlan.createdBy })
      .read()
      .then((user) => {
        if (user && user[0]) setSignedAgreementUser(user[0]);
      })
      .catch((err) => {
        // do nothing
      });
  };

  useEffect(() => {
    if (!props.currentOrg) {
      props.getOrgs();
      props.reload();
    } else if (props.currentOrg) {
      fetchAndSetCustomerInvoices();
    }
  }, [props.currentOrg, props.currentUser]);

  useEffect(() => {
    const merged = mergeTiersWithTemplates(tiers, props.billingPlanTemplates);
    setMergedTiers(merged);
  }, [props.billingPlanTemplates]);

  useEffect(() => {
    // if there is a billing plan created by someone other than the current admin
    // show alert that a plan was made on their behalf
    if (
      !props.isSuperadmin &&
      props.currentUser?.id !== props.billingPlanRamp?.createdBy
    ) {
      setShouldShowAlert(true);
    }
  }, [currentAgreement, props.mspBillingPlan]);

  useEffect(() => {
    if (props.ready && props.mspBillingPlan?.id) {
      setCurrentAgreement(true);
      setMSPBillingPlan(props.mspBillingPlan);

      // Set the selectedTier and selectedTierLevel based on the existing plan
      if (props.mspBillingPlan.templateId && mergedTiers.length > 0) {
        setSelectedTier(props.mspBillingPlan.templateId);
      }

      // Load the createdBy user
      fetchUser();
    } else if (props.ready) {
      const urlTierParam = new URLSearchParams(window.location.search);
      const selectedTierParam = urlTierParam.get("selectedTier");

      // If returning from adding a payment method in Stripe Portal
      if (selectedTierParam) {
        const mspBillingPlanModel = new MSPBillingPlan({
          orgId: props.orgId,
          templateId: selectedTierParam,
        });
        setMSPBillingPlan(mspBillingPlanModel);
        setSelectedTier(selectedTierParam);
        setIsAccordionExpanded(true);
      } else {
        const mspBillingPlanModel = new MSPBillingPlan({ orgId: props.orgId });
        setMSPBillingPlan(mspBillingPlanModel);
      }
    }
  }, [props.ready, props.mspBillingPlan, mergedTiers]);

  useEffect(() => {
    if (props.ready) {
      if (props.billingPlanRamp && props.billingPlanRamp.length > 0) {
        const customRamp = props.billingPlanRamp.pop();

        setMSPCustomRamp(customRamp);
        setSelectedTier(customRamp?.templateId);
        setCurrentCustomRamp(true);
      }
    }
  }, [props.ready, props.billingPlanRamp]);

  useEffect(() => {
    // TODO: need to add additional checks
    // for payment method and selected tier
    if (emailArray.length && selectedTier && didUserAgreeToTermsAndConditions) {
      setIsSubmitOrderButtonDisabled(false);
    } else {
      setIsSubmitOrderButtonDisabled(true);
    }
  }, [emailArray, selectedTier, didUserAgreeToTermsAndConditions]);

  useEffect(() => {
    if (props.ready) {
      if (overrideMin !== "" && selectedTier) {
        setIsCustomAgreementButtonDisabled(false);
      } else {
        setIsCustomAgreementButtonDisabled(true);
      }
    }
  }, [overrideMin, props.ready, selectedTier]);

  const handleTierChange = (event) => {
    const selectedTierId = event.target.value;
    const selectedTierObject = mergedTiers.find(
      (tier) => tier.id === selectedTierId
    );

    if (selectedTierObject) {
      setSelectedTier(selectedTierId);
    } else {
      // TODO: this won't be needed once all templates
      // are returned from account service
      alert("No tier found for the selected id");
    }
  };

  const handleExpandAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  const handleCreateNewAgreement = () => {
    if (currentAgreement) {
      setOpenAgreementModal(true);
    } else {
      handleExpandAccordion();
    }
  };

  const handleViewInvoices = () => {
    handleToggleInvoicesDialog();
  };

  const handleViewUsageReports = () => {
    handleToggleUsageReportsDialog();
  };

  const handleEditPaymentMethod = async () => {
    setIsLaunchingStripePortal(true);

    try {
      const body = {
        redirectUrl: `${window.location.href}?selectedTier=${selectedTier}`,
        customer: props.currentOrg.stripeCustomerId,
      };

      const { data } = await API.stripe.createCustomerSession(body);

      // open stripe hosted customer portal
      window.location.href = data.session.url;

      setIsLaunchingStripePortal(false);
    } catch (e) {
      setIsLaunchingStripePortal(false);
      setErrorMessage(
        `Oops, we had trouble launching the Stripe portal. Details: ${
          e.message || e
        }`
      );
    }
  };

  const toggleStripeLaunchDialog = () => {
    setIsLaunchingStripePortal(!isLaunchingStripePortal);
  };

  const handleToggleBillingContactsDialog = () => {
    setIsBillingContactsDialogOpen(!isBillingContactsDialogOpen);
  };

  const handleEditRecipients = () => {
    handleToggleBillingContactsDialog();
  };

  const handleReloadBillingContacts = () => {
    // refresh billing contacts
    fetchAndConfigureBillingContacts();
  };

  const handleToggleUsageReportsDialog = () => {
    setIsUsageReportsDialogOpen(!isUsageReportsDialogOpen);
  };

  const handleToggleInvoicesDialog = () => {
    setIsInvoicesDialogOpen(!isInvoicesDialogOpen);
  };

  const toggleTermsAndConditions = () => {
    setDidUserAgreeToTermsAndConditions(!didUserAgreeToTermsAndConditions);
  };

  const handleSubmitOrderForm = async () => {
    setIsSaving(true);

    try {
      if (mspBillingPlan && mspCustomRamp?.id) {
        mspBillingPlan.set({
          rampId: mspCustomRamp.id,
          templateId: selectedTier,
          termDate: mspCustomRamp.termEnd,
          isAutoRenew: true,
        });
      } else {
        mspBillingPlan.set({
          templateId: selectedTier,
          termDate: moment(new Date())
            .utc()
            .tz(moment.tz.guess(true))
            .add(364, "days")
            .toISOString(),
          isAutoRenew: true,
        });
      }

      if (mspBillingPlan) {
        const keys = [
          "age",
          "id",
          "created",
          "createdBy",
          "licenses",
          "ramp",
          "template",
        ];

        keys.forEach((key) => {
          mspBillingPlan.unset(key);
        });
      }

      // create billing plan
      await mspBillingPlan["create"]();

      // set new billing plan
      await mspBillingPlan["read"]();
      setMSPBillingPlan(mspBillingPlan);

      setIsSaving(false);
      setSubmitSuccess(true);

      // Reset success message after 3 seconds
      setTimeout(() => {
        setSubmitSuccess(false);
        handleToggleAgreementConfirmationDialog();
        handleExpandAccordion();
        toggleTermsAndConditions();
      }, 3000);
    } catch (e) {
      setIsSaving(false);
      // Optionally, handle error state here
    }
  };

  const CustomDropdown = ({ expanded }) => (
    <KeyboardArrowDownIcon
      sx={{
        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s",
      }}
    />
  );

  const handleSaveCustomAgreement = async () => {
    // alert("Saving a custom agreement");
    // set ramp model with min commit, months 1-6, term start and end date, and org/template ids
    setIsSaving(true);
    // Does this need to set on the billing plan or on the ramp?
    const mspBillingPlanRampModel = new MSPBillingPlanRamp();

    try {
      mspBillingPlanRampModel.set({
        templateId: selectedTier,
        monthOneMin: monthOne,
        monthTwoMin: monthTwo,
        monthThreeMin: monthThree,
        monthFourMin: monthFour,
        monthFiveMin: monthFive,
        monthSixMin: monthSix,
        minCommit: overrideMin,
        termStart: customTermStart.toISOString(),
        termEnd: customTermEnd.toISOString(),
        orgId: props.orgId,
      });

      const rampCrudOperation = mspBillingPlanRampModel?.id
        ? "update"
        : "create";
      await mspBillingPlanRampModel[rampCrudOperation]();

      setIsSaving(false);
      setMSPCustomRamp(mspBillingPlanRampModel);
      setCurrentCustomRamp(true);
      handleResetCustomAgreement();
    } catch {
      setIsSaving(false);
    }
  };

  const handleResetCustomAgreement = () => {
    setOverrideMin("");
    setMonthOne("");
    setMonthTwo("");
    setMonthThree("");
    setMonthFour("");
    setMonthFive("");
    setMonthSix("");
  };

  const handleOverrideMinChange = (event) => {
    setOverrideMin(event.target.value);
    setMonthOne(event.target.value);
    setMonthTwo(event.target.value);
    setMonthThree(event.target.value);
    setMonthFour(event.target.value);
    setMonthFive(event.target.value);
    setMonthSix(event.target.value);
  };

  const handleMonthOneChange = (event) => {
    setMonthOne(event.target.value);
  };

  const handleMonthTwoChange = (event) => {
    setMonthTwo(event.target.value);
  };

  const handleMonthThreeChange = (event) => {
    setMonthThree(event.target.value);
  };

  const handleMonthFourChange = (event) => {
    setMonthFour(event.target.value);
  };

  const handleMonthFiveChange = (event) => {
    setMonthFive(event.target.value);
  };

  const handleMonthSixChange = (event) => {
    setMonthSix(event.target.value);
  };

  const handleChangeCustomTermType = (event) => {
    setCustomTermType(event.target.value);
  };

  const handleAutoRenewChange = async (event) => {
    setIsSaving(true);
    try {
      if (mspBillingPlan) {
        mspBillingPlan.set({
          isAutoRenew: event.target.checked,
        });

        const keys = [
          "age",
          "id",
          "created",
          "createdBy",
          "licenses",
          "ramp",
          "template",
        ];

        keys.forEach((key) => {
          mspBillingPlan.unset(key);
        });

        // create billing plan
        await mspBillingPlan["create"]();

        // set new billing plan
        await mspBillingPlan["read"]();
        setMSPBillingPlan(mspBillingPlan);
      }

      setIsSaving(false);
      setSubmitSuccess(true);

      // Reset success message after 3 seconds
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 3000);
    } catch (e) {
      setIsSaving(false);
    }
  };

  const TermsAndConditions = ({ className }) => {
    return (
      <>
        <p>
          This Order Form (“Order Form”) is entered into as of the effective
          date (“Effective Date”) between Blumira, Inc. (“Blumira”), and the
          customer listed above (“Customer”). This Order Form includes and
          incorporates (i) the above Order Form, (ii) Blumira's MSP Agreement,
          which is set forth at:
          www.blumira.com/managed_service_provider_agreement, (iii) Blumira's
          Pass Through Terms and Conditions, which are set forth at:
          blumira.help/ptt, the MSP Pricing which is located:{" "}
          <Link href="https://blumira.help/msppricing">
            https://blumira.help/msppricing
          </Link>
          , and the service level agreement which is set forth at
          www.blumira.com/sla, all of which are incorporated by reference. There
          shall be no force or effect to any different terms of any Customer
          purchasing documents, unless signed by both parties, after the date
          hereof. Unless otherwise stated at{" "}
          <Link href="https://blumira.help/msppricing">
            https://blumira.help/msppricing
          </Link>
          , when new products or editions are released and made available at{" "}
          <Link href="https://blumira.help/msppricing">
            https://blumira.help/msppricing
          </Link>
          , Customer may access them as a part of this agreement at their
          existing MSP Plan Tier as long as Customer is in good standing with
          their contract.
        </p>

        <p>
          Following the Initial Term as defined by the Subscription start and
          end dates above, this Order Form term shall automatically renew for
          additional successive periods of equal duration to the Initial Term
          (each, a “Renewal Term”) unless either party notifies the other party
          of such party's intention not to renew no less than thirty (30) days
          prior to the expiration of the then-current term. If the Customer does
          not have an Order Form in place with a current term but still has
          sub-accounts on paid editions in their MSP Portal, those will be
          billed at the then-current prices at{" "}
          <Link href="blumira.com/pricing">blumira.com/pricing</Link>.
        </p>
      </>
    );
  };

  const displayDialogMinimumPerMonth = () => {
    if (mspBillingPlan?.ramp) {
      return (
        <td className={dialogClasses.tableRowText}>
          <s
            style={{ color: "grey" }}
          >{`$${mspBillingPlan?.template?.minCommit}`}</s>{" "}
          {`$${mspBillingPlan?.ramp?.monthOneMin}`} per month minimum
        </td>
      );
    } else {
      return (
        <td className={dialogClasses.tableRowText}>
          {`$${mspBillingPlan?.template?.minCommit}`} per month minimum
        </td>
      );
    }
  };

  const handleToggleAgreementConfirmationDialog = () => {
    setIsAgreementConfirmationOpen(!isAgreementConfirmationOpen);
  };

  return (
    <Root>
      <div className={classes.headerLinksRowOuterContainer}>
        <div className={classes.headerLinksRowInnerContainer}>
          <a
            href="https://blumira.help/msppricing"
            className={classes.outlinedHeaderLink}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Learn how billing works <OpenInNewIcon />
          </a>
          <a
            href="https://info.blumira.com/msppricing"
            className={classes.outlinedHeaderLink}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Compare Edition features <OpenInNewIcon />
          </a>
        </div>
        <div className={classes.headerLinksRowInnerContainer}>
          <p>Need help?</p>
          <a
            href="https://blumira.help/msp-team"
            className={classes.outlinedHeaderLink}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Book time with an MSP specialist <OpenInNewIcon />
          </a>
        </div>
      </div>
      <div className={classes.clickableCardContainer}>
        <div className={classes.agreementConfirmationDialogWrap}>
          <Card
            className={
              isAgreementConfirmationOpen
                ? classes.successConfirmationCardStyles
                : classes.clickableCard
            }
            onClick={handleCreateNewAgreement}
          >
            <div>
              <p className={classes.clickableCardTitle}>Current agreement:</p>
              {currentAgreement ? (
                <ul className={classes.currentAgreementList}>
                  <li>
                    <b>Term ends:</b>{" "}
                    {renderFormattedDate(mspBillingPlan?.termDate)}
                  </li>
                  <li>
                    <b>Pricing tier:</b> {mspBillingPlan?.template?.name}
                  </li>
                  <li>
                    <b>Minimum per month:</b>{" "}
                    {`$${
                      mspBillingPlan?.rampId
                        ? mspBillingPlan?.ramp?.monthOneMin
                        : mspBillingPlan?.template?.minCommit
                    }`}
                  </li>
                </ul>
              ) : (
                <p className={classes.clickableCardSecondaryText}>
                  You do not currently have an active agreement. Create a new
                  agreement below.
                </p>
              )}
            </div>
            {currentAgreement ? (
              <p className={classes.clickableCardCTAText}>Agreement Details</p>
            ) : (
              <p className={classes.clickableCardCTAText}>
                Create new agreement
              </p>
            )}
          </Card>

          {isAgreementConfirmationOpen && (
            <Paper
              elevation={10}
              square={false}
              className={classes.agreementConfirmationDialog}
            >
              <p className={classes.congratsText}>Congratulations!</p>
              <p>
                You're new agreement was created successfully! View and manage
                your agreement here.
              </p>
              <Button
                variant={"contained"}
                onClick={handleToggleAgreementConfirmationDialog}
              >
                Okay, Got it
              </Button>
            </Paper>
          )}
        </div>

        <Card className={classes.clickableCard} onClick={handleViewInvoices}>
          <p className={classes.clickableCardTitle}>Invoices (PDF)</p>
          <p className={classes.clickableCardNumber}>
            {currentInvoiceData?.length || 0}
          </p>
          <p className={classes.clickableCardCTAText}>View invoices</p>
        </Card>
        <Card
          className={classes.clickableCard}
          onClick={handleViewUsageReports}
        >
          <p className={classes.clickableCardTitle}>Usage reports (CSV)</p>
          <p className={classes.clickableCardNumber}>
            {props.usageReports?.length || 0}
          </p>
          <p className={classes.clickableCardCTAText}>View usage reports</p>
        </Card>
        <Card
          className={classes.clickableCard}
          onClick={handleEditPaymentMethod}
        >
          <div>
            <p className={classes.clickableCardTitle}>Payment method:</p>
            <p className={classes.clickableCardSecondaryText}>
              {currentPlanData?.paymentMethod
                ? currentPlanData?.paymentMethod
                : " No payment method set up"}
            </p>
          </div>
          <p className={classes.clickableCardCTAText}>
            Edit payment method <OpenInNewIcon size={12} />
          </p>
        </Card>
        <Card className={classes.clickableCard} onClick={handleEditRecipients}>
          <div>
            <p className={classes.clickableCardTitle}>Billing emails go to:</p>
            {emailArray.length ? (
              emailArray.map(({ email = "" }, index) => (
                <p key={index} className={classes.clickableCardSecondaryText}>
                  {email}
                </p>
              ))
            ) : (
              <p className={classes.clickableCardSecondaryText}>
                No recipients set up
              </p>
            )}
          </div>
          <p className={classes.clickableCardCTAText}>Edit recipients</p>
        </Card>
      </div>

      <StyledDialog
        open={isLaunchingStripePortal}
        onClose={toggleStripeLaunchDialog}
      >
        <DialogContent>
          <div className={dialogClasses.stripeLaunchDialogContainer}>
            <p>Redirecting you to your Stripe portal...</p>
            <CircularProgress />
          </div>
        </DialogContent>
      </StyledDialog>

      <Card className={classes.accordionCard}>
        <Accordion
          expanded={isAccordionExpanded}
          onChange={handleExpandAccordion}
          className={classes.accordionContainer}
          sx={{
            "& .MuiAccordionSummary-root": {
              flexDirection: "column",
              alignItems: "stretch",
            },
          }}
        >
          <AccordionSummary>
            <div className={classes.accordionSummaryContainer}>
              <div className={classes.accordionTitleRow}>
                <CustomDropdown expanded={isAccordionExpanded} />
                <p className={classes.accordionTitle}>Create new agreement</p>
              </div>
              {shouldShowAlert && (
                <Alert icon={false} className={classes.alert}>
                  Great news! One of our MSP specialists has put together a
                  custom agreement tailored just for you. To finalize this
                  agreement and get started, simply add your preferred payment
                  method below. If you have any questions along the way, our
                  team is here to help make the process smooth and easy for you.
                </Alert>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div className={classes.agreementStepContainer}>
              {props.isSuperadmin && (
                <div className={classes.superadminDirectionsContainer}>
                  <p className={classes.minimumCommitmentText}>
                    Custom Agreement (Superadmin only)
                  </p>
                  <ul>
                    <li>
                      To create a custom agreement for {props.currentOrg?.name},
                      first select a tier below, then you can override that
                      tier's minimum monthly price as well as add an optional
                      ramp.
                    </li>
                    <li>
                      After saving this custom agreement, the next time{" "}
                      {props.currentOrg?.name} logs in to their MSP Portal they
                      will be able to enter a payment method and complete their
                      agreement from their billing page.
                    </li>
                  </ul>
                </div>
              )}
              <div className={classes.agreementStepInnerContainer}>
                <p className={classes.stepTableTitleText}>
                  Step 1 - Select pricing tier:
                </p>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableRowRoot}>
                        <TableCell /> {/* radio, tier selection column */}
                        <TableCell>
                          <p className={classes.minimumCommitmentText}>
                            Minimum Commitment
                          </p>
                        </TableCell>
                        <TableCell /> {/* 'per user price' column */}
                        <TableCell className={classes.tablePriceCell}>
                          <p className={classes.tableColumnHeaderText}>XDR</p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.tableColumnHeaderText}>SIEM+</p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.tableColumnHeaderText}>
                            SIEM Pro
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.tableColumnHeaderText}>
                            Microsoft 365
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className={classes.tableColumnHeaderText}>
                            Free SIEM
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mergedTiers.map((tier, index) => {
                        const currentPlanTier =
                          props.mspBillingPlan?.template?.tier ?? -1; // Use -1 as fallback

                        // SAs can do whatever they please
                        const isDisabled =
                          !props.isSuperadmin &&
                          currentPlanTier !== -1 &&
                          tier.tier < currentPlanTier;

                        const radioButton = (
                          <FormControlLabel
                            value={tier.id}
                            control={<Radio />}
                            label={tier.name}
                            checked={
                              selectedTier === tier.id ||
                              tier.id === mspCustomRamp?.templateId
                            }
                            onChange={handleTierChange}
                            className={classes.radioButton}
                            disabled={isDisabled}
                          />
                        );

                        return (
                          <>
                            <TableRow
                              key={index}
                              className={`${classes.tableRowRoot} ${
                                isDisabled ? classes.disabledRow : ""
                              }`}
                            >
                              <TableCell
                                className={`${
                                  classes.radioTableCellContainer
                                } ${isDisabled ? classes.disabledCell : ""}`}
                              >
                                {isDisabled ? (
                                  <Tooltip
                                    title="Sorry, you cannot switch to a lower tier until your current contract term is over"
                                    arrow
                                    placement={"bottom-start"}
                                  >
                                    <span>{radioButton}</span>
                                  </Tooltip>
                                ) : (
                                  radioButton
                                )}
                              </TableCell>
                              <TableCell
                                className={
                                  isDisabled ? classes.disabledCell : ""
                                }
                              >
                                {tier.minCommit ? (
                                  <>
                                    <Typography variant="body2">
                                      ${tier.minCommit} per month
                                    </Typography>
                                    <Typography variant="body2">
                                      1 year contract term
                                    </Typography>
                                  </>
                                ) : (
                                  <Typography variant="body2">
                                    No commitment
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell
                                className={
                                  isDisabled ? classes.disabledCell : ""
                                }
                              >
                                <Typography
                                  variant="body1"
                                  className={classes.tablePerUserText}
                                >
                                  Per user price:
                                </Typography>
                              </TableCell>
                              {tier.prices.map((price, priceIndex) => (
                                <TableCell
                                  key={priceIndex}
                                  className={`${classes.tablePriceCell} ${
                                    isDisabled ? classes.disabledCell : ""
                                  }`}
                                >
                                  <Typography variant="body1">
                                    {price}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>

                            {currentCustomRamp &&
                              tier.id === mspCustomRamp?.templateId && (
                                <TableRow
                                  className={classes.finalCustomAgreementWrap}
                                >
                                  <TableCell colSpan={8}>
                                    <MSPCustomRampFields
                                      isSuperadminView={false}
                                      overrideMin={mspCustomRamp.minCommit}
                                      monthOne={mspCustomRamp.monthOneMin}
                                      monthTwo={mspCustomRamp.monthTwoMin}
                                      monthThree={mspCustomRamp.monthThreeMin}
                                      monthFour={mspCustomRamp.monthFourMin}
                                      monthFive={mspCustomRamp.monthFiveMin}
                                      monthSix={mspCustomRamp.monthSixMin}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {props.isSuperadmin && (
                <div className={classes.customAgreementContainer}>
                  <MSPCustomRampFields
                    isSuperadminView={true}
                    overrideMin={overrideMin}
                    monthOne={monthOne}
                    monthTwo={monthTwo}
                    monthThree={monthThree}
                    monthFour={monthFour}
                    monthFive={monthFive}
                    monthSix={monthSix}
                    handleOverrideMinChange={handleOverrideMinChange}
                    handleMonthOneChange={handleMonthOneChange}
                    handleMonthTwoChange={handleMonthTwoChange}
                    handleMonthThreeChange={handleMonthThreeChange}
                    handleMonthFourChange={handleMonthFourChange}
                    handleMonthFiveChange={handleMonthFiveChange}
                    handleMonthSixChange={handleMonthSixChange}
                  />
                  <FormControl sx={{ margin: "50px 0 20px 0" }}>
                    <FormLabel
                      id="term-radio-buttons-group-label"
                      sx={{ color: "black", fontSize: 14, fontWeight: "bold" }}
                    >
                      Term
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="term-radio-buttons-group-label"
                      value={customTermType}
                      onChange={handleChangeCustomTermType}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="standard"
                        control={<Radio />}
                        label="Standard (12 month term starts on 1st of month following MSP submitting order form)"
                        className={classes.customAgreementRadio}
                        classes={{ label: classes.customAgreementRadioLabel }}
                      />
                      <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Custom"
                        className={classes.customAgreementRadio}
                        classes={{ label: classes.customAgreementRadioLabel }}
                      />
                    </RadioGroup>
                  </FormControl>
                  {customTermType === "custom" && (
                    <div className={classes.datePickerContainer}>
                      <DatePicker
                        value={customTermStart}
                        label={"Term Start"}
                        onChange={(newValue) => setCustomTermStart(newValue)}
                        slotProps={{
                          textField: { InputLabelProps: { shrink: true } },
                        }}
                        className={`${classes.customTermDatePicker} ${classes.termStartDatePicker}`}
                      />
                      <DatePicker
                        value={customTermEnd}
                        label={"Term End"}
                        onChange={(newValue) => setCustomTermEnd(newValue)}
                        slotProps={{
                          textField: { InputLabelProps: { shrink: true } },
                        }}
                        className={classes.customTermDatePicker}
                      />
                    </div>
                  )}
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleSaveCustomAgreement}
                      disabled={isCustomAgreementButtonDisabled || isSaving}
                    >
                      {isSaving ? "Saving" : "Save custom agreement"}
                    </Button>
                    <Button onClick={handleResetCustomAgreement}>Reset</Button>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                selectedTier && !emailArray.length
                  ? `${classes.agreementStepContainer} blueHighlight`
                  : classes.agreementStepContainer
              }
            >
              <div className={classes.agreementStepInnerContainer}>
                <p className={classes.stepTitleText}>
                  Step 2 - Set up recipient(s) for billing emails:
                </p>
                {emailArray.length > 0 &&
                  emailArray.map(({ email = "" }, index) => (
                    <p
                      key={index}
                      className={classes.clickableCardSecondaryText}
                    >
                      {email}
                    </p>
                  ))}
                <button
                  className={classes.clickableCardCTAButton}
                  onClick={handleEditRecipients}
                  aria-label="Add recipient"
                  disabled={!selectedTier}
                >
                  Add recipient
                </button>
              </div>
            </div>
            <div>
              <div className={classes.agreementSummaryContainer}>
                <p className={classes.termsAndConditionsTitleText}>
                  Agreement Summary
                </p>
                <p className={classes.boldText}>
                  Minimum per month: $
                  {mspCustomRamp?.monthOneMin
                    ? renderFormattedPrice(mspCustomRamp?.monthOneMin)
                    : renderFormattedPrice(mspBillingPlan?.template?.minCommit)}
                </p>
                <p className={classes.boldText}>
                  Term:{" "}
                  {mspCustomRamp?.termStart
                    ? `${renderFormattedDate(mspCustomRamp.termStart)} -
                      ${renderFormattedDate(mspCustomRamp.termEnd)}`
                    : `${renderFormattedDate(customTermStart)} -
                      ${renderFormattedDate(customTermEnd)}`}
                </p>
              </div>
              <div className={classes.termsAndConditionsContainer}>
                <p className={classes.termsAndConditionsTitleText}>
                  Terms and Conditions
                </p>
                <div className={classes.smallTermsContainer}>
                  <TermsAndConditions />
                </div>
              </div>
              <div
                className={
                  emailArray.length &&
                  selectedTier &&
                  !didUserAgreeToTermsAndConditions
                    ? `${classes.checkboxContainer} blueHighlight`
                    : classes.checkboxContainer
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color={"primary"}
                      onChange={toggleTermsAndConditions}
                      checked={didUserAgreeToTermsAndConditions}
                      disabled={!emailArray.length}
                    />
                  }
                  label="I have read and agree to the Terms and Conditions"
                />
              </div>
            </div>
            <div>
              {submitSuccess && (
                <Alert severity="success" style={{ marginBottom: "1rem" }}>
                  Order form submitted successfully!
                </Alert>
              )}
              <Button
                color={"primary"}
                variant={"contained"}
                disabled={isSubmitOrderButtonDisabled || isSaving}
                onClick={handleSubmitOrderForm}
              >
                {isSaving ? "Submitting" : "Submit Order Form"}
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </Card>

      <MSPBillingContactsDialog
        open={isBillingContactsDialogOpen}
        toggle={handleToggleBillingContactsDialog}
        emailContacts={emailArray}
        emailString={emailString}
        customerId={currentCustomerData?.id}
        reload={handleReloadBillingContacts}
      />

      <MSPInvoicesDialog
        open={isInvoicesDialogOpen}
        toggle={handleToggleInvoicesDialog}
        invoices={currentInvoiceData}
      />

      <MSPUsageReportsDialog
        open={isUsageReportsDialogOpen}
        toggle={handleToggleUsageReportsDialog}
        usageReports={props.usageReports}
      />

      <StyledDialog
        open={openAgreementModal}
        onClose={() => setOpenAgreementModal(false)}
        datacy={"mspAgreementModal"}
        fullWidth
        maxWidth={"lg"}
      >
        <div className={dialogClasses.agreementTitleWrap}>
          <DialogTitle>Current Agreement Details</DialogTitle>
          <div className={dialogClasses.agreementIconWrap}>
            <IconButton
              color={"default"}
              onClick={() => setOpenAgreementModal(false)} // TODO: This needs to print/download something?
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              color={"default"}
              onClick={() => setOpenAgreementModal(false)}
              sx={{ marginRight: "16px" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <DialogContent>
          <table className={dialogClasses.dialogTable}>
            <tbody>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Term Start Date</td>
                <td className={dialogClasses.tableRowText}>
                  {renderFormattedDate(mspBillingPlan?.ramp?.termStart)}
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Term End Date</td>
                <td className={dialogClasses.tableRowText}>
                  {renderFormattedDate(mspBillingPlan?.ramp?.termEnd)}
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Pricing Tier</td>
                <td className={dialogClasses.tableRowText}>
                  {mspBillingPlan?.template?.name}
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Monthly Minimum</td>
                {displayDialogMinimumPerMonth()}
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>
                  Per User Picing By Edition
                </td>
                <td style={{ paddingBottom: 15 }}>
                  <table className={dialogClasses.dialogTable}>
                    <tbody>
                      {mspBillingPlan?.licenses?.map((license, index) => (
                        <tr key={index}>
                          <td className={dialogClasses.pricingTableRowLabel}>
                            {license.name}
                          </td>
                          <td className={dialogClasses.pricingTableRowText}>
                            ${renderFormattedPrice(license.pricePerUser)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>
                  Terms & Conditions
                </td>
                <td
                  className={dialogClasses.tableRowText}
                  style={{ fontSize: 12 }}
                >
                  <TermsAndConditions />
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Agreed By</td>
                <td className={dialogClasses.tableRowText}>
                  {signedAgreementUser?.email || "Administrator"}
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Agreed On</td>
                <td className={dialogClasses.tableRowText}>
                  {renderFormattedDate(mspBillingPlan?.created)}
                </td>
              </tr>
              <tr>
                <td className={dialogClasses.tableRowLabel}>Auto-renewal</td>
                <td className={dialogClasses.tableRowText}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={mspBillingPlan?.isAutoRenew}
                        onChange={handleAutoRenewChange}
                      />
                    }
                    label={
                      mspBillingPlan?.isAutoRenew
                        ? `Enabled (renews automatically ${renderFormattedDate(
                            mspBillingPlan?.termDate
                          )})`
                        : "Disabled"
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
      </StyledDialog>
    </Root>
  );
};

const mapStateToProps = (state) => {
  const orgId = state.location.payload.orgId;

  const currentOrg = state.org.listOrgs.orgs.find((o) => o["id"] === orgId);

  return {
    currentOrg,
    license: state.license,
    orgId: state.location.payload.orgId,
    currentUser: state?.session?.settings?.user,
    isSuperadmin: state.session?.settings?.user?.superadmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrgs: () => dispatch(getOrgs()),
  reload: (force) => dispatch(loadPageData(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MSPBillingPageView);
