import React, { useEffect, useState } from "react";

import {
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  IconButton,
  Link,
  TextField,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  DialogActions,
} from "@mui/material";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";

import { StyledDialog, classes } from "./styles";
import { mspIntegrationMetadata } from "./constants";
import { MSPIntegrationLogo } from "views/Pages/MSPPortal/MSPIntegrationLogo";

import { PSAIntegrationTest } from "lib/models/PSAIntegrationTest";
import { PSASimulateFinding } from "lib/models/PSASimulateFinding";
import MSPIntegrationAccountMapping from "./AccountMapping";
import MSPTicketFlow from "./TicketFlow";
import { PSAIntegration } from "lib/models/PSAIntegration";
import { StyledSteps } from "./TicketFlow/styles";

import findingDetailsImage from "../../../../images/finding-details-screenshot.png";
import { MAPPING_METHODS } from "../helpers";

const betaMSPIntegrationTooltipText =
  "Donut I love apple pie I love jelly-o I love I love lemon drops pie. Carrot cake caramels I love gummies biscuit macaroon marshmallow."; // TODO: figure out what this beta text needs to be...until then enjoy some cupcake ipsum :)

const BLUMIRA_PRIORITIES = [
  { value: "1", label: "Priority 1" },
  { value: "2", label: "Priority 2" },
  { value: "3", label: "Priority 3" },
];

const MSPIntegrationDialog = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [catchallId, setCatchallId] = useState("");
  const [companySyncing, setCompanySyncing] = useState(
    MAPPING_METHODS.companyId.value
  );
  const [companyMapping, setCompanyMapping] = useState([]);
  const [isConnectionButtonDisabled, setIsConnectionButtonDisabled] =
    useState(true);
  const [isTestSuccessful, setIsTestSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);
  const [isTestingConnection, setIsTestingConnection] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isIntegrationConnected, setIsIntegrationConnected] = useState(false);
  const [serviceBoard, setServiceBoard] = useState("");
  const [serviceBoardDisplayText, setServiceBoardDisplayText] = useState("");
  const [expandAccordionOne, setExpandAccordionOne] = useState(true);
  const [expandAccordionTwo, setExpandAccordionTwo] = useState(false);
  const [expandAccordionThree, setExpandAccordionThree] = useState(false);
  const [expandAccordionFour, setExpandAccordionFour] = useState(false);
  const [expandAccordionFive, setExpandAccordionFive] = useState(false);
  const [disabledAccordionTwo, setDisabledAccordionTwo] = useState(true);
  const [disabledAccordionThree, setDisabledAccordionThree] = useState(true);
  const [disabledAccordionFour, setDisabledAccordionFour] = useState(true);
  const [disabledAccordionFive, setDisabledAccordionFive] = useState(true);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultEmailError, setDefaultEmailError] = useState(false);
  const [ticketSyncing, setTicketSyncing] = useState({});
  const [orgToSimulateFrom, setOrgToSimulateFrom] = useState("");
  const [
    isFindingSimulationButtonDisabled,
    setIsFindingSimulationButtonDisabled,
  ] = useState(false);
  const [priorityToSimulateFrom, setPriorityToSimulateFrom] = useState("1");
  const [typeToSimulateFrom, setTypeToSimulateFrom] = useState("Threat");
  const [priorityDisplayText, setPriorityDisplayText] = useState("Priority 1");
  const [isSendingSample, setIsSendingSample] = useState(false);
  const [findingTypes, setFindingTypes] = useState({});
  const [isIntegrationEnabled, setIsIntegrationEnabled] = useState(false);
  const [isEnablingIntegration, setIsEnablingIntegration] = useState(false);
  const [isEnableSuccessful, setIsEnableSuccessful] = useState(false);
  const [enableResultText, setEnableResultText] = useState("");
  const [pendingDeleteIntegration, setPendingDeleteIntegration] =
    useState(false);
  const [isDeletingIntegration, setIsDeletingIntegration] = useState(false);
  const [isServiceBoardUpdated, setIsServiceBoardUpdated] = useState(false);
  const [isTestFindingSuccessful, setIsTestFindingSuccessful] = useState(false);
  const [sourceType, setSourceType] = useState("none");
  const [isTicketFlowButtonDisabled, setIsTicketFlowButtonDisabled] =
    useState(false);

  useEffect(() => {
    if (props.editingModel) {
      setSelectedModel(props.editingModel);
      setCurrentStep(1);
      setDisabledAccordionTwo(false);
    }
  }, [props.editingModel, props.open]);

  useEffect(() => {
    // If one of the fields doesn't have a value, make sure the button is disabled
    if (
      !domainName.length ||
      !companyId.length ||
      !publicKey.length ||
      !privateKey.length
    ) {
      setIsConnectionButtonDisabled(true);
    } else {
      setIsConnectionButtonDisabled(false);
    }
  }, [domainName, companyId, publicKey, privateKey]);

  useEffect(() => {
    // If this field doesn't have a value, make sure the button is disabled
    if (!orgToSimulateFrom.length) {
      setIsFindingSimulationButtonDisabled(true);
    } else {
      setIsFindingSimulationButtonDisabled(false);
    }
  }, [orgToSimulateFrom]);

  useEffect(() => {
    if (selectedModel && selectedModel?.id) {
      if (selectedModel.manageUrl) {
        setDomainName(selectedModel.manageUrl);
      }

      if (selectedModel.companyId) {
        setCompanyId(selectedModel.companyId);
      }

      if (selectedModel.defaultCompany) {
        setCatchallId(selectedModel.defaultCompany);
      }

      if (selectedModel.companySyncing) {
        setDisabledAccordionThree(false);
        setCompanySyncing(selectedModel.companySyncing);
      }

      if (selectedModel.companyMappings) {
        setCompanyMapping(selectedModel.companyMappings);
      }

      if (selectedModel.defaultBoard) {
        const serviceBoardName = props.serviceBoards.find(
          (board) => board.id === parseInt(selectedModel.defaultBoard)
        )?.name;

        setServiceBoard(selectedModel.defaultBoard);
        setServiceBoardDisplayText(serviceBoardName);
        setDisabledAccordionFour(false);
        setIsServiceBoardUpdated(true);
      }

      if (selectedModel.defaultEmail) {
        setDefaultEmail(selectedModel.defaultEmail);
        setDisabledAccordionFive(false);
      }

      if (selectedModel.defaultSource) {
        setSourceType(selectedModel.defaultSource);
      }

      if (selectedModel.enabled) {
        setIsIntegrationEnabled(selectedModel.enabled);
      }

      if (selectedModel.statusSyncing) {
        const { statusSyncing } = selectedModel;
        setTicketSyncing((prevState) => ({ ...prevState, statusSyncing }));
      }

      if (selectedModel.typeSyncing) {
        const { typeSyncing } = selectedModel;
        setTicketSyncing((prevState) => ({ ...prevState, typeSyncing }));
      }

      if (selectedModel.prioritySyncing) {
        const { prioritySyncing } = selectedModel;
        setTicketSyncing((prevState) => ({ ...prevState, prioritySyncing }));
      }

      if (selectedModel.statusMappings) {
        const { statusMappings } = selectedModel;
        const updatedStatusMappings = !statusMappings.length
          ? [
              {
                blumiraStatus: "",
                psaStatus: "",
              },
            ]
          : statusMappings;
        setTicketSyncing((prevState) => ({
          ...prevState,
          statusMappings: updatedStatusMappings,
        }));
      }

      if (selectedModel.priorityMappings) {
        const { priorityMappings } = selectedModel;
        const updatedPriorityMappings = !priorityMappings.length
          ? [
              {
                blumiraPriority: "",
                psaPriority: "",
              },
            ]
          : priorityMappings;
        setTicketSyncing((prevState) => ({
          ...prevState,
          priorityMappings: updatedPriorityMappings,
        }));
      }

      if (selectedModel.typeMappings) {
        const { typeMappings } = selectedModel;
        const updatedTypeMappings = !typeMappings.length
          ? [
              {
                blumiraType: "",
                psaType: "",
              },
            ]
          : typeMappings;
        setTicketSyncing((prevState) => ({
          ...prevState,
          typeMappings: updatedTypeMappings,
        }));
      }
    }
  }, [selectedModel]);

  useEffect(() => {
    // Remove finding type 40: Alert from list shown to users in accordion 5
    const filteredTypes = Object.fromEntries(
      Object.entries(props.findingTypes).filter(
        ([key, value]) => value !== "Alert"
      )
    );

    setFindingTypes(filteredTypes);
  }, [props.findingTypes]);

  useEffect(() => {
    const priorityToDisplay = BLUMIRA_PRIORITIES.filter(
      (p) => p.value === priorityToSimulateFrom
    );

    setPriorityDisplayText(priorityToDisplay[0].label);
  }, [priorityToSimulateFrom, typeToSimulateFrom]);

  useEffect(() => {
    if (
      !defaultEmail.length ||
      defaultEmailError ||
      sourceType === "none" ||
      isSaving
    ) {
      setIsTicketFlowButtonDisabled(true);
    } else {
      setIsTicketFlowButtonDisabled(false);
    }
  }, [sourceType, defaultEmail, defaultEmailError, isSaving]);

  useEffect(() => {
    toggleStatusCheck();
  }, [companyMapping]);

  const getMSPIntegrationList = () => {
    // Only show integrations that are generally available OR the the org has the beta license feature enabled for
    return mspIntegrationMetadata.filter(
      (module) => !module.betaChip || props.license.hasFeature(module?.betaChip)
    );
  };

  const isModuleInBeta = (module) => {
    const { integrationType = "" } = module; // eslint-disable-line no-unused-vars

    return false;
  };

  const isModuleNew = (module) => {
    const { integrationType = "" } = module; // eslint-disable-line no-unused-vars

    return false;
  };

  const handleSelectModule = (module) => {
    setSelectedModel(new PSAIntegration());
    setIsIntegrationConnected(false);
    setCurrentStep(1);
  };

  const handleDomainNameChange = (event) => {
    setDomainName(event.target.value);
  };

  const handlePublicKeyChange = (event) => {
    setPublicKey(event.target.value);
  };

  const handlePrivateKeyChange = (event) => {
    setPrivateKey(event.target.value);
  };

  const handleCompanyIdChange = (event) => {
    setCompanyId(event.target.value);
  };

  const IntegrationButton = (props) => {
    return (
      <Grid item xs={3} className={classes.buttonsGrid}>
        <Button
          variant={"outlined"}
          disabled={props.disabled}
          className={classes.moduleButton}
          datacy={props.module.integrationType}
          onClick={() => {
            // reference paidCta prop so that user can still
            // select to click on the cta link in the card
            if (props.paidCta) return;
            handleSelectModule(props.module);
          }}
        >
          <div className={classes.moduleOptionContainer}>
            <div className={classes.logoCtaContainer}>
              <div className={classes.logoContainer}>
                <MSPIntegrationLogo
                  integrationType={props.module.integrationType}
                  width={120}
                  height={66}
                />
              </div>
            </div>
            <div className={classes.textContainer}>
              <p className={classes.moduleTitleText}>{props.module.title}</p>
              <p className={classes.moduleSubtitleText}>
                {props.module.subtitle}
              </p>
            </div>
          </div>
        </Button>
        {props.paidCta ? (
          <div className={classes.paidPlanCtaContainer}>
            <p>Available on paid plans</p>
            <div>
              <a target={"_blank"} rel="noopener noreferrer" href={"#"}>
                Contact sales
                <OpenInNewIcon />
              </a>
            </div>
          </div>
        ) : props.isBeta ? (
          <Tooltip
            title={betaMSPIntegrationTooltipText}
            placement={"bottom-start"}
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
            >
              <p>Beta</p>
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        ) : props.isNew ? (
          <div
            className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
          >
            <p>New</p>
            <AutoAwesomeIcon />
          </div>
        ) : null}
      </Grid>
    );
  };

  const handleConnectionButton = () => {
    const testPSAModel = new PSAIntegrationTest();
    const body = {
      companyId: companyId,
      publicKey: publicKey,
      privateKey: privateKey,
      manageUrl: domainName,
    };
    setIsTestingConnection(true);
    setErrorMessage("");
    testPSAModel.set(body);

    // Hit the POST endpoint for testing an integration
    testPSAModel
      .create()
      .then(() => {
        // Show success text and change button
        setIsTestSuccessful(true);
        setIsTestingConnection(false);
      })
      .catch((error) => {
        if (error && /401/.test(error)) {
          setErrorMessage(
            "Please verify that your credentials are correct and try again."
          );
        } else if (error && /403/.test(error)) {
          setErrorMessage(
            "You may not have permission to access this resource."
          );
        } else {
          setErrorMessage(
            "Failed to connect to ConnectWise PSA API. Please check your secret values and try again."
          );
        }
        setIsTestingConnection(false);
      });
  };

  const handleSaveButton = () => {
    const body = {
      companyId: companyId,
      publicKey: publicKey,
      privateKey: privateKey,
      manageUrl: domainName,
    };

    setIsSaving(true);
    selectedModel.set(body);

    const crudMethod = selectedModel.id ? "update" : "create";
    selectedModel[crudMethod]()
      .then(() => {
        props.reload(true);
        setIsIntegrationConnected(true);
        setIsTestSuccessful(false);
        setExpandAccordionOne(false);
        setDisabledAccordionTwo(false);
        setExpandAccordionTwo(true);
        setPrivateKey("");
        setPublicKey("");
        setIsSaving(false);
      })
      .catch((error) => {
        setErrorMessage("Failed to save your integration.  Please try again."); // TODO: figure out with this use case error message should be
        setIsSaving(false);
        setIsTestSuccessful(false);
        setIsTestingConnection(false);
      });
  };

  const handleAccordionOneChange = () => {
    setExpandAccordionOne(!expandAccordionOne);
    setExpandAccordionTwo(false);
    setExpandAccordionThree(false);
    setExpandAccordionFour(false);
    setExpandAccordionFive(false);
  };

  const MappingSummary = () => {
    let notMappedCount = "";
    let mappedCount = "";

    if (companyMapping[0].companyId) {
      notMappedCount = companyMapping?.reduce(
        (acc, obj) => acc + (!obj.mapped ? 1 : 0),
        0
      );
      mappedCount = companyMapping?.length - notMappedCount;
    }

    return (
      <div className={classes.accordionSummaryDetails}>
        <div className={classes.accordionTwoSummary}>
          Mapped
          <div className={classes.accordionTwoMappedChip}>{mappedCount}</div>
        </div>
        <div className={classes.accordionTwoSummary}>
          Not Mapped
          <div className={classes.accordionTwoNotMappedChip}>
            {notMappedCount}
          </div>
        </div>
      </div>
    );
  };

  const handleSaveAccountMapping = () => {
    const body = {
      defaultCompany: catchallId.toString(),
      companySyncing,
      companyMappings: companyMapping,
    };

    setIsSaving(true);
    selectedModel.set(body);

    selectedModel
      .update()
      .then(() => {
        props.reload(true);
        setExpandAccordionTwo(false);
        setIsSaving(false);
        setExpandAccordionThree(true);
        setDisabledAccordionThree(false);
      })
      .catch(() => {
        setErrorMessage(
          "Failed to update your integration.  Please try again."
        ); // TODO: figure out with this use case error message should be
        setIsSaving(false);
      });
  };

  const displayConnectedStatus = () => {
    if (
      isTestSuccessful ||
      isIntegrationConnected ||
      selectedModel?.connectionStatus === "OK"
    ) {
      return (
        <div className={classes.accordionOneConnectedChip}>
          <DoNotDisturbOnOutlinedIcon />
          Connected
        </div>
      );
    } else {
      return (
        <div className={classes.accordionOneNotConnectedChip}>
          <DoNotDisturbOnOutlinedIcon />
          Not Connected
        </div>
      );
    }
  };

  const handleServiceBoardChange = (event) => {
    setServiceBoard(event.target.value);
  };

  const handleAccordionTwoChange = () => {
    setExpandAccordionTwo((prevState) => !prevState);
    setExpandAccordionOne(false);
    setExpandAccordionThree(false);
    setExpandAccordionFour(false);
    setExpandAccordionFive(false);
  };

  const handleAccordionThreeChange = () => {
    setExpandAccordionThree(!expandAccordionThree);
    setExpandAccordionOne(false);
    setExpandAccordionTwo(false);
    setExpandAccordionFour(false);
    setExpandAccordionFive(false);
  };

  const handleAccordionFourChange = () => {
    setExpandAccordionFour((prevState) => !prevState);
    setExpandAccordionOne(false);
    setExpandAccordionTwo(false);
    setExpandAccordionThree(false);
    setExpandAccordionFive(false);
  };

  const handleAccordionFiveChange = () => {
    setExpandAccordionFive(!expandAccordionFive);
    setExpandAccordionOne(false);
    setExpandAccordionTwo(false);
    setExpandAccordionThree(false);
    setExpandAccordionFour(false);
  };

  const handleSaveServiceBoardButton = () => {
    // If the stored defaultBoard value has not changed, then just close the modal and open the next one
    if (selectedModel.defaultBoard === String(serviceBoard)) {
      setExpandAccordionThree(false);
      setIsSaving(false);
      setExpandAccordionFour(true);
      setDisabledAccordionFour(false);
      // Otherwise, set and save the new value on the model before closing the accordion, clearing values in accordion 4 & 5, and opening the next modal
    } else {
      const body = {
        defaultBoard: String(serviceBoard),
        enabled: false,
        statusMappings: [],
        statusSyncing: "Default",
        priorityMappings: [],
        prioritySyncing: "Default",
        typeMappings: [],
        typeSyncing: "Default",
      };

      setIsSaving(true);
      selectedModel.set(body);

      selectedModel
        .update()
        .then(() => {
          // Close accordion 3
          setExpandAccordionThree(false);
          setIsSaving(false);
          // Update service board value text in accordion summary section
          const serviceBoardName = props.serviceBoards.find(
            (board) => board.id === parseInt(selectedModel.defaultBoard)
          )?.name;

          setServiceBoardDisplayText(serviceBoardName);
          // Reset ticket syncing radio values and trigger accordion 4 API calls
          setTicketSyncing({});
          setIsServiceBoardUpdated(true);
          // Reset values for sample finding simulation
          setOrgToSimulateFrom("");
          setTypeToSimulateFrom("Threat");
          setPriorityToSimulateFrom("1");
          // Reset findings flow toggle state
          setIsIntegrationEnabled(false);
          // Open accordion 4
          setExpandAccordionFour(true);
          setDisabledAccordionFour(false);
        })
        .catch((error) => {
          setErrorMessage(
            "Failed to update your integration.  Please try again."
          ); // TODO: figure out with this use case error message should be
          setIsSaving(false);
        });
    }
  };

  const handleSaveTicketFlowButton = () => {
    const body = {
      defaultEmail,
      defaultSource: sourceType,
      priorityMappings: ticketSyncing.priorityMappings,
      prioritySyncing: ticketSyncing.prioritySyncing,
      statusMappings: ticketSyncing.statusMappings,
      statusSyncing: ticketSyncing.statusSyncing,
      typeMappings: ticketSyncing.typeMappings,
      typeSyncing: ticketSyncing.typeSyncing,
    };

    setIsSaving(true);
    selectedModel.set(body);

    selectedModel
      .update()
      .then(() => {
        setExpandAccordionFour(false);
        setIsSaving(false);
        setExpandAccordionFive(true);
        setDisabledAccordionFive(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to update your integration.  Please try again."
        ); // TODO: figure out with this use case error message should be
        setIsSaving(false);
      });
  };

  const handleSendSampleFinding = () => {
    const testFindingSimulationModel = new PSASimulateFinding();
    const body = {
      orgId: orgToSimulateFrom,
      priority: priorityToSimulateFrom,
      type: typeToSimulateFrom,
    };
    setIsSendingSample(true);
    setErrorMessage("");
    setIsTestFindingSuccessful(false);
    testFindingSimulationModel.set(body);

    // Hit the POST endpoint for sending a simulation finding
    testFindingSimulationModel
      .create()
      .then(() => {
        setIsSendingSample(false);
        setIsTestFindingSuccessful(true);

        setTimeout(() => {
          setIsTestFindingSuccessful(false);
        }, 5000);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to send a sample finding.  Please try again." // TODO: figure out with this use case error message should be
        );
        setIsSendingSample(false);
      });
  };

  const handleChangeOrgToSimulateFrom = (event) => {
    setOrgToSimulateFrom(event.target.value);
  };

  const handleChangePriorityToSimulateFrom = (event) => {
    setPriorityToSimulateFrom(event.target.value);
  };

  const handleChangeTypeToSimulateFrom = (event) => {
    setTypeToSimulateFrom(event.target.value);
  };

  const handleEnableToggle = (event) => {
    setIsEnablingIntegration(true);
    const body = {
      enabled: event.target.checked,
    };

    setIsEnableSuccessful(false);
    setEnableResultText("");
    setErrorMessage("");
    selectedModel.set(body);

    selectedModel
      .update()
      .then(() => {
        setIsEnablingIntegration(false);
        setIsIntegrationEnabled(!isIntegrationEnabled);
        if (selectedModel.enabled) {
          setIsEnableSuccessful(true);
          setEnableResultText("Ticketing configurations successful");
        } else {
          setIsEnableSuccessful(false);
          setEnableResultText("Ticketing configurations not connected");
        }
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to enable finding flow with ConnectWise PSA.  Please try again." // TODO: figure out with this use case error message should be
        );
        setIsEnablingIntegration(false);
      });
  };

  const handleDoneButton = () => {
    handleAccordionFiveChange();
    setEnableResultText("");
  };

  const toggleStatusCheck = () => {
    const companyMapStatuses = companyMapping.filter(
      (company) => company.enabled === true
    );

    if (companyMapStatuses.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const displayDialogContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Grid
            container
            spacing={2}
            className={classes.moduleOptionsContainer}
            datacy={"mspIntegrationsListModal"}
          >
            {getMSPIntegrationList().map((module, idx) => {
              return (
                <IntegrationButton
                  module={module}
                  key={module.integrationType}
                  isBeta={isModuleInBeta(module)}
                  isNew={isModuleNew(module)}
                />
              );
            })}
          </Grid>
        );
      case 1:
        return (
          <div>
            <div className={classes.titleWrap}>
              <Typography className={classes.stepOneAccordionText}>
                Ticketing Configurations
              </Typography>
            </div>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionOne}
                onChange={handleAccordionOneChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  datacy={"cwmAccordionOne"}
                >
                  <div className={classes.accordionTitle}>
                    ConnectWise PSA API Connection
                    {displayConnectedStatus()}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsContainer}>
                  <Link
                    href="https://blumira.help/psa-cwm"
                    className={classes.setupGuideLink}
                    underline="hover"
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    Setup guide
                    <OpenInNewIcon />
                  </Link>
                  <TextField
                    variant="outlined"
                    label="ConnectWise PSA Host (Domain Name)"
                    value={domainName}
                    onChange={handleDomainNameChange}
                    className={classes.accordionOneTextField}
                    placeholder="https://"
                    datacy={"cwmDomainNameField"}
                  />
                  <TextField
                    variant="outlined"
                    label="Company ID"
                    value={companyId}
                    onChange={handleCompanyIdChange}
                    className={classes.accordionOneTextField}
                    datacy={"cwmCompanyIdField"}
                  />
                  <TextField
                    variant="outlined"
                    label="Public Key"
                    value={publicKey}
                    onChange={handlePublicKeyChange}
                    className={classes.accordionOneTextField}
                    datacy={"cwmPublicKeyField"}
                  />
                  <TextField
                    variant="outlined"
                    label="Private Key"
                    value={privateKey}
                    onChange={handlePrivateKeyChange}
                    className={classes.accordionOneTextField}
                    datacy={"cwmPrivateKeyField"}
                  />
                  <div className={classes.buttonContainer}>
                    {isTestSuccessful ? (
                      <Button
                        variant="contained"
                        className={classes.accordionOneButton}
                        disabled={isConnectionButtonDisabled || isSaving}
                        onClick={handleSaveButton}
                        datacy={"cwmAccordionOneSaveBtn"}
                      >
                        {isSaving
                          ? "Saving..."
                          : "Save and Continue to Account Mapping"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.accordionOneButton}
                        disabled={
                          isConnectionButtonDisabled || isTestingConnection
                        }
                        onClick={handleConnectionButton}
                        datacy={"cwmAccordionOneTestBtn"}
                      >
                        {isTestingConnection
                          ? "Testing Connection..."
                          : "Test Connection"}
                      </Button>
                    )}
                    {isTestSuccessful && (
                      <div
                        className={classes.successContainer}
                        datacy={"cwmTestSuccessText"}
                      >
                        <CheckIcon className={classes.successTextGreen} />
                        <p className={classes.successText}>
                          Successfully Connected
                        </p>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                onChange={handleAccordionTwoChange}
                expanded={expandAccordionTwo}
                disabled={disabledAccordionTwo}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  datacy={"cwmAccordionTwo"}
                >
                  <div className={classes.accordionTitle}>
                    Account Mapping
                    {/* Need to make sure this is still working when we are auto-opening accordions */}
                    {!expandAccordionTwo && !!companyMapping.length && (
                      <MappingSummary />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <MSPIntegrationAccountMapping
                    currentId={selectedModel?.id}
                    catchallId={catchallId}
                    companyMapping={companyMapping}
                    companySyncing={companySyncing}
                    setCompanySyncing={setCompanySyncing}
                    setCompanyMapping={setCompanyMapping}
                    setCatchallId={setCatchallId}
                    blumiraOrganizationsList={props.blumiraOrganizationsList}
                  ></MSPIntegrationAccountMapping>
                  <Button
                    variant="contained"
                    className={classes.integrationMappingButton}
                    disabled={
                      (!companyMapping && !catchallId) ||
                      isSaving ||
                      toggleStatusCheck()
                    }
                    onClick={handleSaveAccountMapping}
                    datacy={"cwmAccordionTwoSaveBtn"}
                  >
                    {isSaving ? "Saving..." : "Save Account Mapping"}
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionThree}
                disabled={disabledAccordionThree}
                onChange={handleAccordionThreeChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  datacy={"cwmAccordionThree"}
                >
                  <div className={classes.accordionTitle}>
                    ConnectWise PSA Service Board
                    {selectedModel && selectedModel.defaultBoard && (
                      <span className={classes.accordionSummaryDetails}>
                        {serviceBoardDisplayText}
                      </span>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsContainer}>
                  <FormControl className={classes.accordionThreeSelect}>
                    <InputLabel id="board-select-label">
                      Service Board
                    </InputLabel>
                    <Select
                      labelId="board-select-label"
                      id="board-select"
                      value={serviceBoard}
                      label="Service Board"
                      onChange={handleServiceBoardChange}
                      datacy={"cwmServiceBoardField"}
                    >
                      {props.serviceBoards.map((board) => (
                        <MenuItem
                          key={board.id}
                          value={board.id}
                          datacy={`cwmServiceBoardItem-${board.name}`}
                        >
                          {board.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.accordionThreeButton}
                    onClick={handleSaveServiceBoardButton}
                    datacy={"cwmAccordionThreeSaveBtn"}
                    disabled={isSaving}
                  >
                    {isSaving ? "Saving..." : "Save Board"}
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionFour}
                disabled={disabledAccordionFour}
                onChange={handleAccordionFourChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                  className={classes.accordionTitle}
                  datacy={"cwmAccordionFour"}
                >
                  Blumira-to-ConnectWise PSA Ticket Flow
                </AccordionSummary>
                <AccordionDetails>
                  <MSPTicketFlow
                    boardId={serviceBoard}
                    defaultEmail={defaultEmail}
                    setDefaultEmail={setDefaultEmail}
                    defaultEmailError={defaultEmailError}
                    setDefaultEmailError={setDefaultEmailError}
                    ticketSyncing={ticketSyncing}
                    setTicketSyncing={setTicketSyncing}
                    filteredTypes={findingTypes}
                    isServiceBoardUpdated={isServiceBoardUpdated}
                    sourceType={sourceType}
                    setSourceType={setSourceType}
                    sourceTypesList={props.sourceTypes}
                  />

                  <Button
                    variant="contained"
                    onClick={handleSaveTicketFlowButton}
                    disabled={isTicketFlowButtonDisabled}
                    datacy={"cwmAccordionFourSaveBtn"}
                  >
                    {isSaving ? "Saving..." : "Save"}
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionFive}
                disabled={disabledAccordionFive}
                onChange={handleAccordionFiveChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5-content"
                  id="panel5-header"
                  className={classes.accordionTitle}
                  datacy={"cwmAccordionFive"}
                >
                  Verify and Enable/Disable
                </AccordionSummary>
                <AccordionDetails>
                  <StyledSteps>
                    <li>
                      <Typography className="step-label">
                        Step 1 – Send a sample finding to verify that everything
                        is working as expected
                      </Typography>
                      <div className={classes.accordionFiveStepOne}>
                        <div className={classes.accordionFiveSection}>
                          <table>
                            <tbody>
                              <tr>
                                <td className={classes.tableCellOne}>
                                  Simulate from
                                </td>
                                <td className={classes.tableCellTwo}>
                                  <FormControl fullWidth>
                                    <Select
                                      value={orgToSimulateFrom}
                                      onChange={handleChangeOrgToSimulateFrom}
                                      inputProps={{
                                        "aria-label":
                                          "Select an organization to simulate from",
                                      }}
                                      datacy={"accordionFiveTestOrgSelect"}
                                    >
                                      {props.blumiraOrganizationsList.map(
                                        (org) => (
                                          <MenuItem
                                            value={org.id}
                                            key={org.id}
                                            datacy={"accordionFiveTestOrgItem"}
                                          >
                                            {org.name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.tableCellOne}>
                                  Detection rule
                                </td>
                                <td className={classes.tableCellTwo}>
                                  Simulation Test Rule
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.tableCellOne}>
                                  Priority
                                </td>
                                <td className={classes.tableCellTwo}>
                                  <FormControl fullWidth>
                                    <Select
                                      value={priorityToSimulateFrom}
                                      onChange={
                                        handleChangePriorityToSimulateFrom
                                      }
                                      inputProps={{
                                        "aria-label":
                                          "Select a priority level to simulate",
                                      }}
                                    >
                                      {BLUMIRA_PRIORITIES.map((priority) => (
                                        <MenuItem
                                          value={priority.value}
                                          key={priority.value}
                                        >
                                          {priority.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.tableCellOne}>Type</td>
                                <td className={classes.tableCellTwo}>
                                  <FormControl fullWidth>
                                    <Select
                                      value={typeToSimulateFrom}
                                      onChange={handleChangeTypeToSimulateFrom}
                                      inputProps={{
                                        "aria-label":
                                          "Select a finding type to simulate",
                                      }}
                                    >
                                      {Object.values(findingTypes).map(
                                        (type) => (
                                          <MenuItem value={type} key={type}>
                                            {type}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className={`${classes.tableCellOne} ${classes.lastTableCellOne}`}
                                >
                                  Analysis
                                </td>
                                <td className={classes.tableCellTwo}>
                                  {`Test analysis for a ${priorityDisplayText} ${typeToSimulateFrom} finding.`}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className={classes.tableCellTwo}>
                                  <Button
                                    variant="outlined"
                                    startIcon={<SendIcon />}
                                    className={classes.sendFindingsButton}
                                    onClick={handleSendSampleFinding}
                                    disabled={
                                      isSendingSample ||
                                      isFindingSimulationButtonDisabled
                                    }
                                  >
                                    {isSendingSample
                                      ? "Sending sample finding..."
                                      : "Send sample finding"}
                                  </Button>
                                  {isTestFindingSuccessful && (
                                    <div
                                      className={classes.successContainer}
                                      datacy={"cwmTestSuccessText"}
                                    >
                                      <CheckIcon
                                        className={classes.successTextGreen}
                                      />
                                      <p className={classes.successText}>
                                        Successfully Sent
                                      </p>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          className={`${classes.accordionFiveSection} ${classes.accordionFiveImageWrap}`}
                        >
                          <figure className={classes.accordionFiveFigure}>
                            <img
                              src={findingDetailsImage}
                              alt="Screenshot of findings details page from within the Blumira app"
                              className={classes.accordionFiveImage}
                            />
                            <figcaption
                              className={classes.accordionFiveCaption}
                            >
                              What a finding looks like in Blumira
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Typography className="step-label">
                        Step 2 – Enable the full flow of Blumira findings
                      </Typography>
                      <div
                        className={`${classes.buttonContainer} ${classes.toggleContainer}`}
                      >
                        <FormControlLabel
                          control={<Switch />}
                          label="Enable"
                          className={classes.accordionFiveToggle}
                          onChange={handleEnableToggle}
                          checked={isIntegrationEnabled}
                          datacy={"cwmPSAToggleSwitch"}
                        />
                        {enableResultText && (
                          <div
                            className={classes.successContainer}
                            datacy={"cwmToggleSuccessText"}
                          >
                            {isEnableSuccessful ? (
                              <CheckIcon className={classes.successTextGreen} />
                            ) : (
                              <CloseIcon className={classes.successTextRed} />
                            )}
                            <p
                              className={
                                isEnableSuccessful
                                  ? `${classes.successText} ${classes.successTextGreen}`
                                  : `${classes.successText} ${classes.successTextRed}`
                              }
                            >
                              {enableResultText}
                            </p>
                          </div>
                        )}
                      </div>
                      <Button
                        variant="contained"
                        onClick={handleDoneButton}
                        disabled={isEnablingIntegration}
                        datacy={"cwmAccordionFiveDoneBtn"}
                      >
                        Done
                      </Button>
                    </li>
                  </StyledSteps>
                </AccordionDetails>
              </Accordion>
            </Card>
          </div>
        );
    }
  };

  const displayDialogTitle = () => {
    return currentStep === 0
      ? "Available MSP Integrations"
      : "MSP Integration Details";
  };

  const resetModal = () => {
    setErrorMessage("");
    setExpandAccordionOne(true);
    setExpandAccordionTwo(false);
    setExpandAccordionThree(false);
    setExpandAccordionFour(false);
    setExpandAccordionFive(false);
    setPendingDeleteIntegration(false);
    setSelectedModel(null);
    setTimeout(() => setCurrentStep(0), 200);
  };

  const toggleModal = () => {
    props.toggleModal();
    resetModal();
  };

  const handlePendingDelete = () => {
    setPendingDeleteIntegration(true);
  };

  const handleConfirmPendingDelete = () => {
    setErrorMessage(false);
    setIsDeletingIntegration(true);

    selectedModel
      .delete()
      .then(() => {
        // This works but not ideal solution
        window.location.reload(true);
        toggleModal();
      })
      .catch((e) => {
        setErrorMessage(
          "There was an issue deleting your integration.  Please Try Again." // TODO: figure out with this use case error message should be
        );
        setPendingDeleteIntegration(false);
        setIsDeletingIntegration(false);
      });
  };

  const handleClearPendingDelete = () => {
    setPendingDeleteIntegration(false);
  };

  const displayDialogActions = () => {
    if (currentStep === 1 && pendingDeleteIntegration) {
      return (
        <div className={classes.dialogActionsBtnContainer}>
          Are you sure you want to remove this integration?
          <br />
          <Button
            onClick={handleConfirmPendingDelete} // Actually call API to delete integration
            disabled={!!isDeletingIntegration}
            className={classes.errorTextButton}
            variant={"text"}
            style={{ marginLeft: 5 }}
            datacy={"psaConfirmDeleteBtn"}
          >
            {isDeletingIntegration ? "Removing..." : "Confirm"}
          </Button>
          <Button
            onClick={handleClearPendingDelete}
            disabled={!!isDeletingIntegration}
            color={"primary"}
            variant={"contained"}
            style={{ marginLeft: 5 }}
          >
            Cancel
          </Button>
        </div>
      );
    } else if (currentStep === 1 && selectedModel && selectedModel.id) {
      return (
        <div className={classes.dialogActionsBtnContainer}>
          <Button
            disabled={!!isDeletingIntegration}
            onClick={handlePendingDelete}
            className={classes.errorContainedButton}
            variant={"contained"}
            datacy={"psaDeleteBtn"}
          >
            {isDeletingIntegration ? "Removing..." : "Remove"}
          </Button>
        </div>
      );
    }
  };

  return (
    <StyledDialog
      open={props.open}
      onClose={toggleModal}
      maxWidth={"lg"}
      fullWidth
      datacy={"psaIntegrationsModal"}
    >
      <DialogTitle>
        <div className={classes.modalTitleWrap}>
          {displayDialogTitle()}
          <IconButton onClick={toggleModal} datacy={"psaCloseModalButton"}>
            <CloseIcon />
          </IconButton>
        </div>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogTitle>
      <DialogContent>{displayDialogContent()}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        {displayDialogActions()}
      </DialogActions>
    </StyledDialog>
  );
};

export default MSPIntegrationDialog;
