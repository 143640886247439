import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import Link from "redux-first-router-link";

import { Card, Menu, CircularProgress } from "@mui/material";

// ICONS
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const PREFIX = "VisualizationList";
const classes = {
  reportOuterContainer: `${PREFIX}-reportOuterContainer`,
  expandAndTitleContainer: `${PREFIX}-expandAndTitleContainer`,
  iconPlaceholder: `${PREFIX}-iconPlaceholder`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleDescriptionContainer: `${PREFIX}-titleDescriptionContainer`,
  imageContainer: `${PREFIX}-imageContainer`,
  reportTitle: `${PREFIX}-reportTitle`,
  reportDescription: `${PREFIX}-reportDescription`,
  toggleContainer: `${PREFIX}-toggleContainer`,
  reportDetailsContainer: `${PREFIX}-reportDetailsContainer`,
  noDataTextContainer: `${PREFIX}-noDataTextContainer`,
  noDataText: `${PREFIX}-noDataText`,
  menu: `${PREFIX}-menu`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.reportOuterContainer}`]: {
    width: "100%",
    display: "flex",
    borderRadius: 10,
    boxShadow: "none",
    padding: "0 20px",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.expandAndTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.iconPlaceholder}`]: {
    width: 25,
  },
  [`& .${classes.titleContainer}`]: {
    display: "flex",
    paddingLeft: theme.shape.padding,
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.titleDescriptionContainer}`]: {
    paddingLeft: theme.shape.padding,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  [`& .${classes.imageContainer}`]: {
    padding: "10px 0",
  },
  [`& .${classes.reportTitle}`]: {
    fontSize: 20,
    marginBottom: 0,
    fontWeight: 500,
  },
  [`& .${classes.reportDescription}`]: {
    fontSize: 13,
  },
  [`& .${classes.toggleContainer}`]: {
    height: 100,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.reportDetailsContainer}`]: {
    height: 350,
    position: "relative",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.noDataTextContainer}`]: {
    padding: "20px 45px",
  },
  [`& .${classes.noDataText}`]: {
    margin: 0,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  [`& .${classes.menu}`]: {
    padding: 10,
    "& a": {
      fontSize: 16,
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  },
}));

/**
 * VisualizationList: For rendering collapsible visualizations
 * @param {array} listData - array of data objects see popularReportList.js for example
 * @param {boolean} isLoading - optional param to show loading indicators in data cards
 * @param {boolean} standAlone - optional param to slightly alter card styles and text when the component is used outside of the Popular Reports page
 */

const VisualizationList = (props) => {
  const { theme, listData, isLoading, onClick, standAlone } = props;

  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [leftMarginForGraph, setLeftMarginForGraph] = useState(100);
  const [clickThroughFieldName, setClickThroughFieldName] = useState(null);
  const [clickThroughFieldValue, setClickThroughFieldValue] = useState(null);

  useEffect(() => {
    // need to adjust the graph layout here
    // to account for if a report was previously open
    // but no data was present,
    // then the time or the org was changed
    // causing data to now exist for the previously opened report
    isOpen && adjustLeftMarginForGraph(isOpen);
  }, [listData]);

  const getTickTextColor = () => {
    const color = theme === "shadow" ? "white" : "black";
    return color;
  };

  const getLeftMargin = (data = []) => {
    // get all lengths of label strings
    const arrayOfStringLengths = data
      .map(({ label }) => label?.split("").length)
      .filter((d) => !!d);
    let longestLength = Math.max(...arrayOfStringLengths);
    // add some more love to the margin to not cut off data
    let margintoReturn = 100 + longestLength * 3;
    return margintoReturn;
  };

  const adjustLeftMarginForGraph = (reportId) => {
    // set margin for nivo graph
    let data = listData.find((report) => report?.id === reportId)?.data || [];
    if (data.length > 0) {
      let newLeftMarginForGraph = getLeftMargin(data);
      setLeftMarginForGraph(newLeftMarginForGraph);
    }
  };

  const toggleReport = (reportId) => {
    if (reportId === isOpen) {
      setIsOpen(false);
    } else {
      if (onClick && isOpen === false) {
        onClick();
      }
      adjustLeftMarginForGraph(reportId);
      setIsOpen(reportId);
    }
  };

  const getSortedData = ({ data = [] }) => {
    // by default horizontal chart is bottom heavy
    // ensure that data is sorted properly by value
    return data.sort((a, b) => a.value - b.value);
  };

  const handleClick = ({ indexValue = null }, event, field) => {
    // first item in config groupBy is used
    // to set extra filter for clickthrough
    setClickThroughFieldName(field);

    // indexValue is provided by the node from the
    // native click event of the responsive bar
    setClickThroughFieldValue(indexValue);

    event.preventDefault();
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
  };

  return listData.map((report, index) => {
    if (!report.model) return null;
    return (
      <StyledCard
        className={classes.reportOuterContainer}
        key={report.id}
        style={standAlone ? {} : { margin: "10px 0" }}
        datacy={`popularReportCard-${index}`}
      >
        <div
          onClick={() => toggleReport(report.id)}
          className={classes.toggleContainer}
        >
          <div className={classes.expandAndTitleContainer}>
            {isLoading ? (
              <CircularProgress size={25} />
            ) : isOpen === report.id ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
            <div className={classes.titleContainer}>
              <img
                alt={`${report.model.name}`}
                src={report.image}
                className={classes.imageContainer}
                style={{ width: report.imageWidth || 100 }}
              />
              <div className={classes.titleDescriptionContainer}>
                <p className={classes.reportTitle}>{report.model.name}</p>
                <p className={classes.reportDescription}>
                  {report.model.description || ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        {!isLoading &&
          isOpen === report.id &&
          (!report.data.length ? (
            <div
              className={classes.noDataTextContainer}
              datacy={`popularReportAccordion-${index}`}
            >
              <p className={classes.noDataText}>
                {standAlone
                  ? "No data to show"
                  : "No data to show, try changing the time period"}
              </p>
            </div>
          ) : (
            <div className={classes.reportDetailsContainer}>
              <div
                style={{ height: "100%", width: "100%", position: "absolute" }}
              >
                <Menu
                  keepMounted
                  open={mouseY !== null}
                  onClose={() => setMouseY(null)}
                  classes={{ paper: classes.menu }}
                  anchorReference={"anchorPosition"}
                  anchorPosition={
                    mouseY !== null && mouseX !== null
                      ? { top: mouseY, left: mouseX }
                      : undefined
                  }
                >
                  <Link
                    target={"_blank"}
                    to={{
                      type: "PAGE",
                      payload: {
                        orgId: props.orgId,
                        toplevel: "reporting",
                        secondlevel: "builder",
                        id1: report.model.id,
                        query: {
                          "created.gt": Object.values(
                            report.request.queryParams
                          ).find(
                            (qp) =>
                              qp.field === "created" && qp.operator === "gt"
                          ).value,
                          "created.lt": Object.values(
                            report.request.queryParams
                          ).find(
                            (qp) =>
                              qp.field === "created" && qp.operator === "lt"
                          ).value,
                          [`${clickThroughFieldName}.eq`]:
                            clickThroughFieldValue,
                        },
                      },
                    }}
                  >
                    View report
                  </Link>
                </Menu>
                <ResponsiveBar
                  padding={0.7}
                  indexBy={"label"}
                  enableGridX={true}
                  enableGridY={false}
                  enableLabel={false}
                  layout={"horizontal"}
                  data={getSortedData(report)}
                  onClick={(n, e) => handleClick(n, e, report.field)} // pass in node and event
                  margin={{
                    top: 50,
                    right: 100,
                    bottom: 50,
                    left: leftMarginForGraph,
                  }}
                  axisBottom={{
                    tickSize: 0,
                    // solution via SO to ensure we
                    // have no floats on the x axis
                    format: (e) => Math.floor(e) === e && e,
                  }}
                  theme={{
                    axis: {
                      ticks: {
                        text: {
                          fill: getTickTextColor(),
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          ))}
      </StyledCard>
    );
  });
};

VisualizationList.defaultProps = {
  listData: [],
  isLoading: false,
};

VisualizationList.propTypes = {
  isLoading: PropTypes.bool,
  listData: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { location } = state;
  return {
    orgId: location.payload.orgId,
  };
};

export default connect(mapStateToProps, null)(VisualizationList);
